import pick from 'lodash/pick';
import { initiatePrivileged, updateListingState, updateManuallyPayment, updateMilestoneRevison } from '../../util/api';
import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import * as log from '../../util/log';
import { fetchCurrentUserHasOrdersSuccess, fetchCurrentUser } from '../../ducks/user.duck';
import moment from 'moment';
import { transitions } from '../../transactions/transactionProcessAcceptedBid';
import { types as sdkTypes } from '../../util/sdkLoader';
// ================ Action types ================ //

export const SET_INITIAL_VALUES = 'app/CheckoutPage/SET_INITIAL_VALUES';

export const INITIATE_ORDER_REQUEST = 'app/CheckoutPage/INITIATE_ORDER_REQUEST';
export const INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/INITIATE_ORDER_SUCCESS';
export const INITIATE_ORDER_ERROR = 'app/CheckoutPage/INITIATE_ORDER_ERROR';

export const CONFIRM_PAYMENT_REQUEST = 'app/CheckoutPage/CONFIRM_PAYMENT_REQUEST';
export const CONFIRM_PAYMENT_SUCCESS = 'app/CheckoutPage/CONFIRM_PAYMENT_SUCCESS';
export const CONFIRM_PAYMENT_ERROR = 'app/CheckoutPage/CONFIRM_PAYMENT_ERROR';

export const SPECULATE_TRANSACTION_REQUEST = 'app/CheckoutPage/SPECULATE_TRANSACTION_REQUEST';
export const SPECULATE_TRANSACTION_SUCCESS = 'app/CheckoutPage/SPECULATE_TRANSACTION_SUCCESS';
export const SPECULATE_TRANSACTION_ERROR = 'app/CheckoutPage/SPECULATE_TRANSACTION_ERROR';

export const STRIPE_CUSTOMER_REQUEST = 'app/CheckoutPage/STRIPE_CUSTOMER_REQUEST';
export const STRIPE_CUSTOMER_SUCCESS = 'app/CheckoutPage/STRIPE_CUSTOMER_SUCCESS';
export const STRIPE_CUSTOMER_ERROR = 'app/CheckoutPage/STRIPE_CUSTOMER_ERROR';

export const INITIATE_INQUIRY_REQUEST = 'app/CheckoutPage/INITIATE_INQUIRY_REQUEST';
export const INITIATE_INQUIRY_SUCCESS = 'app/CheckoutPage/INITIATE_INQUIRY_SUCCESS';
export const INITIATE_INQUIRY_ERROR = 'app/CheckoutPage/INITIATE_INQUIRY_ERROR';

export const BID_INITIATE_ORDER_REQUEST = 'app/CheckoutPage/BID_INITIATE_ORDER_REQUEST';
export const BID_INITIATE_ORDER_SUCCESS = 'app/CheckoutPage/BID_INITIATE_ORDER_SUCCESS';
export const BID_INITIATE_ORDER_ERROR = 'app/CheckoutPage/BID_INITIATE_ORDER_ERROR';

// ================ Reducer ================ //
const { UUID } = sdkTypes;
const initialState = {
  listing: null,
  orderData: null,
  speculateTransactionInProgress: false,
  speculateTransactionError: null,
  speculatedTransaction: null,
  isClockInSync: false,
  transaction: null,
  initiateOrderError: null,
  confirmPaymentError: null,
  stripeCustomerFetched: false,
  initiateInquiryInProgress: false,
  initiateInquiryError: null,
  bidInitiateOrderProgress: null,
  bidInitiateOrderData: null,
  bidInitiateOrderError: null,
};

export default function checkoutPageReducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case SET_INITIAL_VALUES:
      return { ...initialState, ...payload };

    case SPECULATE_TRANSACTION_REQUEST:
      return {
        ...state,
        speculateTransactionInProgress: true,
        speculateTransactionError: null,
        speculatedTransaction: null,
      };
    case SPECULATE_TRANSACTION_SUCCESS: {
      // Check that the local devices clock is within a minute from the server
      const lastTransitionedAt = payload.transaction?.attributes?.lastTransitionedAt;
      const localTime = new Date();
      const minute = 60000;
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculatedTransaction: payload.transaction,
        isClockInSync: Math.abs(lastTransitionedAt?.getTime() - localTime.getTime()) < minute,
      };
    }
    case SPECULATE_TRANSACTION_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return {
        ...state,
        speculateTransactionInProgress: false,
        speculateTransactionError: payload,
      };

    case INITIATE_ORDER_REQUEST:
      return { ...state, initiateOrderError: null };
    case INITIATE_ORDER_SUCCESS:
      return { ...state, transaction: payload };
    case INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, initiateOrderError: payload };

    case CONFIRM_PAYMENT_REQUEST:
      return { ...state, confirmPaymentError: null };
    case CONFIRM_PAYMENT_SUCCESS:
      return state;
    case CONFIRM_PAYMENT_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, confirmPaymentError: payload };

    case STRIPE_CUSTOMER_REQUEST:
      return { ...state, stripeCustomerFetched: false };
    case STRIPE_CUSTOMER_SUCCESS:
      return { ...state, stripeCustomerFetched: true };
    case STRIPE_CUSTOMER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, stripeCustomerFetchError: payload };

    case INITIATE_INQUIRY_REQUEST:
      return { ...state, initiateInquiryInProgress: true, initiateInquiryError: null };
    case INITIATE_INQUIRY_SUCCESS:
      return { ...state, initiateInquiryInProgress: false };
    case INITIATE_INQUIRY_ERROR:
      return { ...state, initiateInquiryInProgress: false, initiateInquiryError: payload };

    case BID_INITIATE_ORDER_REQUEST:
      return { ...state, bidInitiateOrderProgress: true, bidInitiateOrderError: null };
    case BID_INITIATE_ORDER_SUCCESS:
      return { ...state, bidInitiateOrderData: payload, bidInitiateOrderProgress: false, bidInitiateOrderError: null };
    case BID_INITIATE_ORDER_ERROR:
      console.error(payload); // eslint-disable-line no-console
      return { ...state, bidInitiateOrderError: payload, bidInitiateOrderProgress: false };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const setInitialValues = initialValues => ({
  type: SET_INITIAL_VALUES,
  payload: pick(initialValues, Object.keys(initialState)),
});

const initiateOrderRequest = () => ({ type: INITIATE_ORDER_REQUEST });

const initiateOrderSuccess = order => ({
  type: INITIATE_ORDER_SUCCESS,
  payload: order,
});

const initiateOrderError = e => ({
  type: INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

const confirmPaymentRequest = () => ({ type: CONFIRM_PAYMENT_REQUEST });

const confirmPaymentSuccess = orderId => ({
  type: CONFIRM_PAYMENT_SUCCESS,
  payload: orderId,
});

const confirmPaymentError = e => ({
  type: CONFIRM_PAYMENT_ERROR,
  error: true,
  payload: e,
});

export const speculateTransactionRequest = () => ({ type: SPECULATE_TRANSACTION_REQUEST });

export const speculateTransactionSuccess = transaction => ({
  type: SPECULATE_TRANSACTION_SUCCESS,
  payload: { transaction },
});

export const speculateTransactionError = e => ({
  type: SPECULATE_TRANSACTION_ERROR,
  error: true,
  payload: e,
});

export const stripeCustomerRequest = () => ({ type: STRIPE_CUSTOMER_REQUEST });
export const stripeCustomerSuccess = () => ({ type: STRIPE_CUSTOMER_SUCCESS });
export const stripeCustomerError = e => ({
  type: STRIPE_CUSTOMER_ERROR,
  error: true,
  payload: e,
});

export const initiateInquiryRequest = () => ({ type: INITIATE_INQUIRY_REQUEST });
export const initiateInquirySuccess = () => ({ type: INITIATE_INQUIRY_SUCCESS });
export const initiateInquiryError = e => ({
  type: INITIATE_INQUIRY_ERROR,
  error: true,
  payload: e,
});

const bidInitiateOrderRequest = () => ({ type: BID_INITIATE_ORDER_REQUEST });

const bidInitiateOrderSuccess = order => ({
  type: BID_INITIATE_ORDER_SUCCESS,
  payload: order,
});

const bidInitiateOrderError = e => ({
  type: BID_INITIATE_ORDER_ERROR,
  error: true,
  payload: e,
});

/* ================ Thunks ================ */

export const initiateOrder = (
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition,
) => (dispatch, getState, sdk) => {
  dispatch(initiateOrderRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;
  const { deliveryMethod, quantity, bookingDates, bidprice, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  const orderData = orderParams || {};
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
    ...orderData,
  };
  const bodyParams =
  {
    processAlias,
    transition: transitionName,
    params: transitionParams,
  };
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSucces = response => {
    const entities = denormalisedResponseEntities(response);
    const order = entities[0];
    dispatch(initiateOrderSuccess(order));
    dispatch(fetchCurrentUserHasOrdersSuccess(true));
    return order;
  };

  const handleError = e => {
    dispatch(initiateOrderError(storableError(e)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(e, 'initiate-order-failed', {
      ...transactionIdMaybe,
      listingId: orderParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    throw e;
  };
  return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
    .then(handleSucces)
    .catch(handleError);
};


export const confirmPayment = (transactionId, transitionName, transitionParams = {}) => async (
  dispatch,
  getState,
  sdk
) => {
  dispatch(confirmPaymentRequest());
  const bodyParams = {
    id: transactionId,
    transition: transitionName,
    params: transitionParams,
  };

  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  try {
    const response = await sdk.transactions.transition(bodyParams, queryParams);
    const order = response?.data?.data;
    const newTransactionid = order?.id?.uuid
    try {
      const { acceptBidTransactionId, autoPayment, manuallyPayment, firstInstallmentFailed, comAcceptedbid, secondinsatllmentfailed, bidData } = order?.attributes?.protectedData?.bidData || {};
      const companyListingId = bidData?.bid.companyListingId || "";
      let transition;
      let paidData;
      if ((autoPayment == "twoMilestonecompleted") || (manuallyPayment == "bothInsatllmentPaidManually") || (manuallyPayment == "secondInsatllmentPaidManually")) {
        transition = "transition/milestone-final-auto-complete";
      } else if ((autoPayment == "threeMilestonecompleted") || (manuallyPayment == "manualfirstInsatllmentPaid")) {
        transition = "transition/milestone-auto-complete";
      } else if (comAcceptedbid) {
        transition = "transition/accept-bid";
      }
      if (firstInstallmentFailed === "paid" || secondinsatllmentfailed === 'paid') {
        updateMilestoneRevison({ acceptBidTransactionId, firstInstallmentFailed, secondinsatllmentfailed })
      }
      if ((manuallyPayment == "bothInsatllmentPaidManually") ||
        (manuallyPayment == "manualfirstInsatllmentPaid") || (manuallyPayment == "secondInsatllmentPaidManually")) {
        updateManuallyPayment({ acceptBidTransactionId, manuallyPayment })
      }
      if (transition) {
        sdk.transactions.transition(
          {
            id: new UUID(acceptBidTransactionId),
            transition: transition,
            params: {
              protectedData: {
                newTransactionid: newTransactionid
              }
            }
          },
          { expand: true }
        ).then(res => {
          //update complete listing if NOT comAcceptedbid =/transition/accept-bid
          if (autoPayment || manuallyPayment) {
            const payload = {
              id: companyListingId,
              publicData: { currentState: 'completed' }
            };
            updateListingState(payload)
          }
        });
      }
    } catch (innerError) {
      console.error(innerError, '&&& &&& => innerError');
    }

    dispatch(confirmPaymentSuccess(order.id));
    return order;
  } catch (error) {
    dispatch(confirmPaymentError(storableError(error)));
    const transactionIdMaybe = transactionId ? { transactionId: transactionId.uuid } : {};
    log.error(error, 'initiate-order-failed', { ...transactionIdMaybe });
    throw error;
  }
};

export const sendMessage = params => (dispatch, getState, sdk) => {
  const message = params.message;
  const orderId = params.id;

  if (message) {
    return sdk.messages
      .send({ transactionId: orderId, content: message })
      .then(() => {
        return { orderId, messageSuccess: true };
      })
      .catch(e => {
        log.error(e, 'initial-message-send-failed', { txId: orderId });
        return { orderId, messageSuccess: false };
      });
  } else {
    return Promise.resolve({ orderId, messageSuccess: true });
  }
};

/**
 * Initiate transaction against default-inquiry process
 * Note: At this point inquiry transition is made directly against Marketplace API.
 *       So, client app's server is not involved here unlike with transitions including payments.
 *
 * @param {*} inquiryParams contains listingId and protectedData
 * @param {*} processAlias 'default-inquiry/release-1'
 * @param {*} transitionName 'transition/inquire-without-payment'
 * @returns
 */
export const initiateInquiryWithoutPayment = (inquiryParams, processAlias, transitionName) => (
  dispatch,
  getState,
  sdk
) => {
  dispatch(initiateInquiryRequest());

  if (!processAlias) {
    const error = new Error('No transaction process attached to listing');
    log.error(error, 'listing-process-missing', {
      listingId: listing?.id?.uuid,
    });
    dispatch(initiateInquiryError(storableError(error)));
    return Promise.reject(error);
  }

  const bodyParams = {
    transition: transitionName,
    processAlias,
    params: inquiryParams,
  };
  const queryParams = {
    include: ['provider'],
    expand: true,
  };

  return sdk.transactions
    .initiate(bodyParams, queryParams)
    .then(response => {
      const transactionId = response.data.data.id;
      dispatch(initiateInquirySuccess());
      return transactionId;
    })
    .catch(e => {
      dispatch(initiateInquiryError(storableError(e)));
      throw e;
    });
};

/**
 * Initiate or transition the speculative transaction with the given
 * booking details
 *
 * The API allows us to do speculative transaction initiation and
 * transitions. This way we can create a test transaction and get the
 * actual pricing information as if the transaction had been started,
 * without affecting the actual data.
 *
 * We store this speculative transaction in the page store and use the
 * pricing info for the booking breakdown to get a proper estimate for
 * the price with the chosen information.
 */
export const speculateTransaction = (
  orderParams,
  processAlias,
  transactionId,
  transitionName,
  isPrivilegedTransition
) => (dispatch, getState, sdk) => {
  dispatch(speculateTransactionRequest());

  // If we already have a transaction ID, we should transition, not
  // initiate.
  const isTransition = !!transactionId;

  const { deliveryMethod, quantity, bookingDates, ...otherOrderParams } = orderParams;
  const quantityMaybe = quantity ? { stockReservationQuantity: quantity } : {};
  const bookingParamsMaybe = bookingDates || {};

  // Parameters only for client app's server
  const orderData = deliveryMethod ? { deliveryMethod } : {};
  // Parameters for Marketplace API
  const transitionParams = {
    ...quantityMaybe,
    ...bookingParamsMaybe,
    ...otherOrderParams,
    cardToken: 'CheckoutPage_speculative_card_token'
  };

  const bodyParams =
  {
    processAlias: 'default-accept-bid/release-1',
    transition: transitions.REQUEST_PAYMENT,
    params: transitionParams,

  }
  const queryParams = {
    include: ['booking', 'provider'],
    expand: true,
  };

  const handleSuccess = response => {
    const entities = denormalisedResponseEntities(response);
    if (entities.length !== 1) {
      throw new Error('Expected a resource in the speculate response');
    }
    const tx = entities[0];
    dispatch(speculateTransactionSuccess(tx));
  };

  const handleError = e => {
    log.error(e, 'speculate-transaction-failed', {
      listingId: transitionParams.listingId.uuid,
      ...quantityMaybe,
      ...bookingParamsMaybe,
      ...orderData,
    });
    return dispatch(speculateTransactionError(storableError(e)));
  };
  // if (isTransition && isPrivilegedTransition) {
  //   // transition privileged
  //   return transitionPrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
  //     .then(handleSuccess)
  //     .catch(handleError);
  // } else if (isTransition) {
  //   // transition non-privileged
  //   return sdk.transactions
  //     .transitionSpeculative(bodyParams, queryParams)
  //     .then(handleSuccess)
  //     .catch(handleError);
  // }
  //  else if (isPrivilegedTransition) {
  // initiate privileged
  return initiatePrivileged({ isSpeculative: true, orderData, bodyParams, queryParams })
    .then(handleSuccess)
    .catch(handleError);
  // }
  //  else {
  //   // initiate non-privileged
  //   return sdk.transactions
  //     .initiateSpeculative(bodyParams, queryParams)
  //     .then(handleSuccess)
  //     .catch(handleError);
  // }
};

// StripeCustomer is a relantionship to currentUser
// We need to fetch currentUser with correct params to include relationship
export const stripeCustomer = () => (dispatch, getState, sdk) => {
  dispatch(stripeCustomerRequest());

  return dispatch(fetchCurrentUser({ include: ['stripeCustomer.defaultPaymentMethod'] }))
    .then(response => {
      dispatch(stripeCustomerSuccess());
    })
    .catch(e => {
      dispatch(stripeCustomerError(storableError(e)));
    });
};

export const bidIntiateOrder = (
  orderParams,
  processAlias,
  requestTransition,
  userlistingId
) => (dispatch, getState, sdk) => {
  try {

    dispatch(bidInitiateOrderRequest());
    const { completionDate, maxRevisions, noteToCompany, paymentPlan, attachmentAwsFiles,
      attachmentCloudinary, totalBid, listingId } = orderParams;
    const showComcompletionDate = (completionDate && completionDate.date) || "";
    const date = new Date(showComcompletionDate) || "";
    const options = { month: 'short', day: 'numeric', year: 'numeric' };
    const formattedDate = date.toLocaleDateString('en-US', options);

    const bodyParams =
    {
      processAlias,
      transition: requestTransition,
      params: {
        listingId: listingId,
        protectedData: {
          maxRevisions, noteToCompany, paymentPlan, attachmentAwsFiles, attachmentCloudinary,
          userlistingId, companyListingId: listingId.uuid,
          bid: {
            companyListingId: listingId.uuid,
            price: totalBid?.amount,
            currency: totalBid.currency,
            completeDate: moment(completionDate?.date).unix(),
            showEmailprice: totalBid?.amount / 100,
            showEmaildate: formattedDate,
            // attachment: bidData?.attachment,
          },
        },
      },
    };

    const queryParams = {
      include: ['booking', 'customer'],
      expand: true,
    };

    const handleError = e => {
      dispatch(bidInitiateOrderError(storableError(e)));
      const transactionIdMaybe = {};
      log.error(e, 'initiate-order-failed', {
        ...transactionIdMaybe,
        ...orderData,
      });
      throw e;
    };

    const orderData = { bidInitiate: true, stockReservationQuantity: 1, bookingStart: completionDate, bookingEnd: completionDate, bidData: { completionDate: completionDate.date, maxRevisions, noteToCompany, paymentPlan, totalBid } };

    return initiatePrivileged({ isSpeculative: false, orderData, bodyParams, queryParams })
      .then(async (res) => {
        const entities = denormalisedResponseEntities(res);
        const order = entities[0];
        // try {
        //   await  updateTransactionMetadata({ id: order.id.uuid, isProviderRead: false, isCustomerRead: false })
        // } catch (e) {
        //   console.log(e, '&&& &&& => e');book
        // }
        // updateBidListing ({ listingId, transactionId: order.id.uuid });
        // .then(res => {
        //   updateListing({ listingId: params.id, currentUserId: currentUser.id.uuid })
        dispatch(bidInitiateOrderSuccess(order));
        return res;
      })
      .catch(handleError);
  }
  catch (e) {
    return dispatch(bidInitiateOrderError(storableError(e)));
  }
};
