import { fetchPageAssets } from '../../ducks/hostedAssets.duck';
export const ASSET_NAME = 'landing-page';
import { parse } from '../../util/urlHelpers';
import { constructQueryParamName, isOriginInUse, isStockInUse } from '../../util/search';
import { createImageVariantConfig } from '../../util/sdkLoader';
import { storableError } from '../../util/errors';
import { addMarketplaceEntities } from '../../ducks/marketplaceData.duck';
import {
  parseDateFromISO8601,
  getExclusiveEndDate,
  addTime,
  subtractTime,
  daysBetween,
  getStartOf,
} from '../../util/dates';
const RESULT_PAGE_SIZE = 24;

export const LANDING_LISTINGS_REQUEST = 'app/LandingPage/LANDING_LISTINGS_REQUEST';
export const LANDING_LISTINGS_SUCCESS = 'app/LandingPage/LANDING_LISTINGS_SUCCESS';
export const LANDING_LISTINGS_ERROR = 'app/LandingPage/LANDING_LISTINGS_ERROR';
export const LANDING_PROLISTINGS_SUCCESS = 'app/LandingPage/LANDING_PROLISTINGS_SUCCESS';
export const LANDING_PROLASTEDLISTINGS_SUCCESS = 'app/LandingPage/LANDING_PROLASTEDLISTINGS_SUCCESS';
export const LANDING_COMPANYLASTEDLISTINGS_SUCCESS = 'app/LandingPage/LANDING_COMPANYLASTEDLISTINGS_SUCCESS';

const initialState = {
  pagination: null,
  searchParams: null,
  searchInProgress: false,
  searchListingsError: null,
  currentPageResultIds: [],
  proResultIds: [],
  proLastedResultIds: [],
  companyLastedResultIds: [],
};

const resultIds = data => data.data.map(l => l.id);

const landingPageReducer = (state = initialState, action = {}) => {
  const { type, payload } = action;
  switch (type) {
    case LANDING_LISTINGS_REQUEST:
      return {
        ...state,
        searchParams: payload.searchParams,
        searchInProgress: true,
        searchMapListingIds: [],
        searchListingsError: null,
      };
    case LANDING_LISTINGS_SUCCESS:
      return {
        ...state,
        currentPageResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };
    case LANDING_PROLISTINGS_SUCCESS:
      return {
        ...state,
        proResultIds: resultIds(payload.data),
        pagination: payload.data.meta,
        searchInProgress: false,
      };

    case LANDING_PROLASTEDLISTINGS_SUCCESS: return {
      ...state,
      proLastedResultIds: resultIds(payload.data),
      pagination: payload.data.meta,
      searchInProgress: false,
    };
    case LANDING_COMPANYLASTEDLISTINGS_SUCCESS: return {
      ...state,
      companyLastedResultIds: resultIds(payload.data),
      pagination: payload.data.meta,
      searchInProgress: false,
    };
    case LANDING_LISTINGS_ERROR:
      // eslint-disable-next-line no-console
      console.error(payload);
      return { ...state, searchInProgress: false, searchListingsError: payload };
    default:
      return state;
  }
};

export default landingPageReducer;
export const searchListingsRequest = searchParams => ({
  type: LANDING_LISTINGS_REQUEST,
  payload: { searchParams },
});

export const searchListingsSuccess = response => ({
  type: LANDING_LISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const searchProListingsSuccess = response => ({
  type: LANDING_PROLISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const searchProlastedListingsSuccess = response => ({
  type: LANDING_PROLASTEDLISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const searchLastedListingsSuccess = response => ({
  type: LANDING_COMPANYLASTEDLISTINGS_SUCCESS,
  payload: { data: response.data },
});
export const searchListingsError = e => ({
  type: LANDING_LISTINGS_ERROR,
  error: true,
  payload: e,
});

export const searchCompanyListings = (searchParams, config) => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));
  return sdk.listings
    .query({ pub_companyListingShow: true })
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };

      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(searchListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};
export const searchCompanyLastedListings = (searchParams, config) => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));
  return sdk.listings
    .query({ pub_userRole: "company", pub_companyListingShow: false })
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(searchLastedListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};
export const searchProListings = (searchParams, config) => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));
  return sdk.listings
    .query({ pub_proListingShow: true, })
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };

      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(searchProListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};
export const searchProlastedListings = (searchParams, config) => (dispatch, getState, sdk) => {
  dispatch(searchListingsRequest(searchParams));
  return sdk.listings
    .query({ pub_userRole: "professional", pub_proListingShow: false })
    .then(response => {
      const listingFields = config?.listing?.listingFields;
      const sanitizeConfig = { listingFields };
      dispatch(addMarketplaceEntities(response, sanitizeConfig));
      dispatch(searchProlastedListingsSuccess(response));
      return response;
    })
    .catch(e => {
      dispatch(searchListingsError(storableError(e)));
      throw e;
    });
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  const pageAsset = { landingPage: `content/pages/${ASSET_NAME}.json` };
  const queryParams = parse(search, {
    latlng: ['origin'],
    latlngBounds: ['bounds'],
  });

  const { page = 1, address, origin, ...rest } = queryParams;
  const originMaybe = isOriginInUse(config) && origin ? { origin } : {};

  const {
    aspectWidth = 1,
    aspectHeight = 1,
    variantPrefix = 'listing-card',
  } = config.layout.listingImage;
  const aspectRatio = aspectHeight / aspectWidth;
  const searchCompanyCall = searchCompanyListings(
    {
      ...rest,
      ...originMaybe,
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images'],
      'fields.listing': [
        'title',
        'geolocation',
        'price',
        'publicData.listingType',
        'publicData.transactionProcessAlias',
        'publicData.unitType',
        // These help rendering of 'purchase' listings,
        // when transitioning from search page to listing page
        'publicData',
        'publicData.shippingEnabled',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    },
    config
  );
  const searchProCall = searchProListings(
    {
      ...rest,
      ...originMaybe,
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images'],
      'fields.image': [`variants.${variantPrefix}`, `variants.${variantPrefix}-2x`],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'fields.listing': [
        'title',
        'geolocation',
        'price',
        'publicData.listingType',
        'publicData.transactionProcessAlias',
        'publicData.unitType',
        // These help rendering of 'purchase' listings,
        // when transitioning from search page to listing page
        'publicData',
        'publicData.shippingEnabled',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      // 'fields.image': [
      //   'variants.scaled-small',
      //   'variants.scaled-medium',
      //   `variants.${variantPrefix}`,
      //   `variants.${variantPrefix}-2x`,
      // ],
      // ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      // ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    },
    config
  );
  const searchProLastedCall = searchProlastedListings(
    {
      ...rest,
      ...originMaybe,
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images'],
      'fields.listing': [
        'title',
        'geolocation',
        'price',
        'publicData.listingType',
        'publicData.transactionProcessAlias',
        'publicData.unitType',
        // These help rendering of 'purchase' listings,
        // when transitioning from search page to listing page
        'publicData',
        'publicData.shippingEnabled',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    },
    config
  );
  const searchCompanyLastedCall = searchCompanyLastedListings(
    {
      ...rest,
      ...originMaybe,
      page,
      perPage: RESULT_PAGE_SIZE,
      include: ['author', 'images'],
      'fields.listing': [
        'title',
        'geolocation',
        'price',
        'publicData.listingType',
        'publicData.transactionProcessAlias',
        'publicData.unitType',
        // These help rendering of 'purchase' listings,
        // when transitioning from search page to listing page
        'publicData',
        'publicData.shippingEnabled',
      ],
      'fields.user': ['profile.displayName', 'profile.abbreviatedName'],
      'fields.image': [
        'variants.scaled-small',
        'variants.scaled-medium',
        `variants.${variantPrefix}`,
        `variants.${variantPrefix}-2x`,
      ],
      ...createImageVariantConfig(`${variantPrefix}`, 400, aspectRatio),
      ...createImageVariantConfig(`${variantPrefix}-2x`, 800, aspectRatio),
      'limit.images': 1,
    },
    config
  );

  return Promise.all([dispatch(searchCompanyCall), dispatch(searchProCall), dispatch(searchProLastedCall), dispatch(searchCompanyLastedCall),
  dispatch(fetchPageAssets(pageAsset, true))])
};


