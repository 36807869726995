

export const transitions = {
    INQUIRE: 'transition/inquire',
    ACCEPT: 'transition/accept-bid',
    DECLINE: 'transition/decline-bid',
    EXPIRE: 'transition/pro-expire',
    ACCEPT_JOB:"transition/pro-accept-job",
    DECLINE_JOB:"transition/pro-decline-job",
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
    INITIAL: 'initial',
    INQUIRY: 'inquiry',
    ACCEPTED: 'accepted',
    DECLINED: 'declined',
    DECLINEJOB: 'decline-job',
    EXPIRED: 'expired',
    ACCEPTJOB:"accept-job"
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
    // id is defined only to support Xstate format.
    // However if you have multiple transaction processes defined,
    // it is best to keep them in sync with transaction process aliases.
    id: 'default-bid/release-1',

    // This 'initial' state is a starting point for new transaction
    initial: states.INITIAL,

    // States
    states: {
        [states.INITIAL]: {
            on: {
                [transitions.INQUIRE]: states.INQUIRY,
            },
        },
        [states.INQUIRY]: {
            on: {
                [transitions.ACCEPT]: states.ACCEPTED,
                [transitions.DECLINE]: states.DECLINED,
                
            },
        },
        [states.ACCEPTED]: {
            on: {
                [transitions.EXPIRE]: states.EXPIRED,
                [transitions.ACCEPT_JOB]: states.ACCEPTJOB,
                [transitions.DECLINE_JOB]: states.DECLINEJOB,
                
            },
         },
         [states.ACCEPTJOB]: { type: 'final' },
        [states.DECLINED]: { type: 'final' },
        [states.EXPIRED]: { type: 'final' },
    },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
    return [
        transitions.INQUIRE,
        transitions.ACCEPT,
        transitions.DECLINE,
        transitions.EXPIRE,
        transitions.ACCEPT_JOB,
        transitions.DECLINE_JOB
    ].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isCustomerReview = transition => {
    return false;
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first
export const isProviderReview = transition => {
    return false;
};

// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
    return [transitions.INQUIRE].includes(
        transition
    );
};

// Check when transaction is completed (booking over)
export const isCompleted = transition => {
    const txCompletedTransitions = [
        transitions.ACCEPT,
        transitions.DECLINE,
        transitions.EXPIRE,
        transitions.ACCEPT_JOB,
        transitions.DECLINE_JOB
    ];
    return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
    return false;
};

export const statesNeedingProviderAttention = [states.INQUIRY];
