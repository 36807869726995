export const progressStateMap = {
    draft: 'Draft',// Initial state
    published: 'Listed',// Available or active state
    closed: 'Un-Listed',// No longer active or available
    progress: 'In Progress', // Accepted By Company
    accepted: 'Bid Accepted', // Accepted By Pro
    final: 'Final Revision',// Nearing completion
    completed: 'Completed'// Finished, no further action needed
};

// Function to get progress state
export const progressState = (key) => {
    return progressStateMap[key] || 'N/A';
};

//filters attribute

export const companyFilter = [
    // {
    //     title: 'Category',
    //     component: 'RefinementList',
    //     attribute: 'categories.label',
    //     searchablePlaceholder: 'category',
    // },
    {
        title: 'Software',
        component: 'RefinementList',
        attribute: 'proSoftwareTools.label',
        searchablePlaceholder: 'category',
    },
    {
        title: 'Discipline',
        component: 'RefinementList',
        attribute: 'disciplines.label',//if user role is company 
        searchablePlaceholder: 'discipline',
    },
    {
        title: 'Required Pro',
        component: 'RefinementList',
        attribute: 'proSoftwareToolsSubCat.label',
        searchablePlaceholder: 'SubCategory',
    },
    {
        title: 'Spoken Languages',
        component: 'RefinementList',
        attribute: 'spokenLanguages.label',
        searchablePlaceholder: 'SubCategoryChild',
    },
];

export const professionalFilter = [
    {
        title: 'Software',
        component: 'RefinementList',
        attribute: 'proSoftwareTools.label',
        searchablePlaceholder: 'category',
    },
    {
        title: 'Discipline',
        component: 'RefinementList',
        attribute: 'specifyDiscipline',//if user role is company disciplines.label
        searchablePlaceholder: 'discipline',
    },
    {
        title: 'Budget',
        component: 'RefinementList',
        attribute: 'budgetLabel',
        searchablePlaceholder: 'budgetLabel',
    },
    {
        title: 'Required Pro',
        component: 'RefinementList',
        attribute: 'proSoftwareToolsSubCat.label',
        searchablePlaceholder: 'SubCategory',
    },
    {
        title: 'Ideal Timeframe',
        component: 'RefinementList',
        attribute: 'idealTimeframe',
        searchablePlaceholder: 'SubCategoryChild',
    },
];

export const maxImgSizeMB = 10;

export const typeSenseUrl = 'https://5fewgxo13d5.typeform.com/to/K4qG4IAW'

export const allowedExtensions = ['image/jpeg', 'image/png', 'application/pdf'];

