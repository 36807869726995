/**
 * Usage without sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w" />
 *
 * Usage with sizes:
 *   <ResponsiveImage
 *     alt="ListingX"
 *     image={imageDataFromSDK}
 *     variants={['landscape-crop', 'landscape-crop2x']}
 *     sizes="(max-width: 600px) 100vw, 50vw"
 *   />
 *   // produces:
 *   <img
 *     alt="ListingX"
 *     src="url/to/landscape-crop.jpg"
 *     srcSet="url/to/landscape-crop.jpg 400w, url/to/landscape-crop2x.jpg 800w"
 *     sizes="(max-width: 600px) 100vw, 50vw" />
 *
 *   // This means that below 600px image will take as many pixels there are available on current
 *   // viewport width (100vw) - and above that image will only take 50% of the page width.
 *   // Browser decides which image it will fetch based on current screen size.
 *
 * NOTE: for all the possible image variant names and their respective
 * sizes, see the API documentation.
 */

import React from 'react';
import { arrayOf, oneOfType, string } from 'prop-types';
import classNames from 'classnames';
import { FormattedMessage } from '../../util/reactIntl';
import { propTypes } from '../../util/types';

import NoImageIcon from './NoImageIcon';
import css from './ResponsiveImage.module.css';

 function getUrlVariants(secureUrl, variant) {
  
  // Define the variants
  const variants = {
      'listing-card-6x': { transformation: 'w_2400,h_2400', height: 2400, width: 2400 },
      'listing-card-4x': { transformation: 'w_1600,h_1600', height: 1600, width: 1600 },
      'listing-card-2x': { transformation: 'w_800,h_800', height: 800, width: 800 },
      'listing-card': { transformation: 'w_400,h_400', height: 400, width: 400 },
      'landscape-crop': { transformation: 'c_crop,w_400,h_267', height: 267, width: 400 },
      'landscape-crop2x': { transformation: 'c_crop,w_800,h_533', height: 533, width: 800 },
      'landscape-crop4x': { transformation: 'c_crop,w_1600,h_1066', height: 1066, width: 1600 },
      'landscape-crop6x': { transformation: 'c_crop,w_2400,h_1602', height: 1602, width: 2400 },
      'scaled-small': { transformation: 'w_320,h_320', height: 320, width: 320 },
      'scaled-medium': { transformation: 'w_750,h_750', height: 750, width: 750 },
      'scaled-large': { transformation: 'w_1024,h_1024', height: 1024, width: 1024 },
      'scaled-xlarge': { transformation: 'w_2400,h_2400', height: 2400, width: 2400 },
      'square-small': { transformation: 'c_crop,w_240,h_240', height: 240, width: 240 },
      'square-small2x': { transformation: 'c_crop,w_480,h_480', height: 480, width: 480 },
      'facebook': { transformation: 'c_crop,w_1200,h_630', height: 630, width: 1200 },
      'twitter': { transformation: 'c_crop,w_600,h_314', height: 314, width: 600 },
      'default': { transformation: '' }
  };
  const splittedURL = secureUrl.split('/upload');

  if (splittedURL && splittedURL.length == 2 && variants[variant]) {
    return { variants, imageUrl: (splittedURL[0] + '/upload/' + variants[variant].transformation + splittedURL[1]) };
  }

  return { variants };
} 

const ResponsiveImage = props => {
  const {
    className,
    rootClassName,
    alt,
    noImageMessage,
    image,
    url,
    variants,
    dimensions,
    ...rest
  } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (url && typeof url == 'string' && variants && variants.length) {
    const { imageUrl } = getUrlVariants(url, variants[0]);
    const imgProps = {
      className: classes,
      src: imageUrl,
      ...rest,
    };
  
    return <img alt={alt} {...imgProps} />;
  } else if (url && typeof url == 'string') {
    return url.includes(".pdf") ?
    <img src="https://res.cloudinary.com/dic6op0in/image/upload/v1723813064/catgoryImages/image-20240814-182236_ukhqpg.png" alt="no image icon" />
    : <img alt={alt} src={url} />;
    // Alamin check it no image card should be like this
    // https://res.cloudinary.com/dic6op0in/image/upload/v1723813064/catgoryImages/image-20240814-182236_ukhqpg.png
  } else if (image == null || variants.length === 0) {
    const noImageClasses = classNames(rootClassName || css.root, css.noImageContainer, className);

    const noImageMessageText = noImageMessage || <FormattedMessage id="ResponsiveImage.noImage" />;
    return (
      <div className={noImageClasses}>
        <div className={css.noImageWrapper}>
        <img src="https://res.cloudinary.com/dic6op0in/image/upload/v1723813064/catgoryImages/image-20240814-182236_ukhqpg.png" alt="no image icon" />
          {/* <NoImageIcon className={css.noImageIcon} />
          <div className={css.noImageText}>{noImageMessageText}</div> */}
        </div>
      </div>
    );
  }

  const imageVariants = image.attributes.variants;

  const srcSet = variants
    .map(variantName => {
      const variant = imageVariants[variantName];

      if (!variant) {
        // Variant not available (most like just not loaded yet)
        return null;
      }
      return `${variant.url} ${variant.width}w`;
    })
    .filter(v => v != null)
    .join(', ');

  const imgProps = {
    className: classes,
    srcSet,
    ...rest,
  };

  return <img alt={alt} {...imgProps} />;
};

ResponsiveImage.defaultProps = {
  className: null,
  rootClassName: null,
  image: null,
  sizes: null,
  noImageMessage: null,
};

ResponsiveImage.propTypes = {
  className: string,
  rootClassName: string,
  alt: string.isRequired,
  image: oneOfType([propTypes.image, propTypes.imageAsset]),
  variants: arrayOf(string),
  sizes: string,
  noImageMessage: string,
};

export default ResponsiveImage;
