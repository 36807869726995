import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import css from './IconClose.module.css';
const SIZE_SMALL = 'small';

const IconClose = props => {
  const { className, rootClassName, size } = props;
  const classes = classNames(rootClassName || css.root, className);

  if (size === SIZE_SMALL) {
    return (
      <svg className={classes} width="9" height="9" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M2.175 8.396l2.482-2.482 2.482 2.482a.889.889 0 1 0 1.258-1.257L5.914 4.657l2.482-2.483A.89.89 0 0 0 7.139.917L4.657 3.4 2.175.918A.888.888 0 1 0 .917 2.174L3.4 4.657.918 7.139a.889.889 0 1 0 1.257 1.257"
          fillRule="evenodd"
        />
      </svg>
    );
  }

  return (
<svg width="39" height="39" viewBox="0 0 39 39" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M31.331 29.6065C31.4442 29.7197 31.534 29.8542 31.5953 30.0021C31.6566 30.15 31.6881 30.3086 31.6881 30.4688C31.6881 30.6289 31.6566 30.7875 31.5953 30.9354C31.534 31.0834 31.4442 31.2178 31.331 31.331C31.2177 31.4443 31.0833 31.5341 30.9354 31.5954C30.7874 31.6566 30.6288 31.6882 30.4687 31.6882C30.3086 31.6882 30.15 31.6566 30.002 31.5954C29.8541 31.5341 29.7197 31.4443 29.6064 31.331L19.4999 21.223L9.39346 31.331C9.16477 31.5597 8.85461 31.6882 8.5312 31.6882C8.20778 31.6882 7.89762 31.5597 7.66893 31.331C7.44024 31.1023 7.31177 30.7922 7.31177 30.4688C7.31177 30.1453 7.44024 29.8352 7.66893 29.6065L17.7769 19.5L7.66893 9.39352C7.44024 9.16483 7.31177 8.85467 7.31177 8.53126C7.31177 8.20784 7.44024 7.89768 7.66893 7.66899C7.89762 7.4403 8.20778 7.31183 8.5312 7.31183C8.85461 7.31183 9.16477 7.4403 9.39346 7.66899L19.4999 17.777L29.6064 7.66899C29.8351 7.4403 30.1453 7.31183 30.4687 7.31183C30.7921 7.31183 31.1023 7.4403 31.331 7.66899C31.5597 7.89768 31.6881 8.20784 31.6881 8.53126C31.6881 8.85467 31.5597 9.16483 31.331 9.39352L21.223 19.5L31.331 29.6065Z" fill="#B06B5D"/>
</svg>

  );
};

const { string } = PropTypes;

IconClose.defaultProps = {
  className: null,
  rootClassName: null,
};

IconClose.propTypes = {
  className: string,
  rootClassName: string,
};

export default IconClose;
