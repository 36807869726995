import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_HOUR } from '../../util/types';

import css from './OrderBreakdown.module.css';
import moment from 'moment';

const LineItemBasePriceMaybe = props => {
  const { lineItems, code, intl, orderData } = props;

  const completeDate = orderData && orderData?.bid?.completeDate || "";
  const formattedDate = moment.unix(completeDate).format('DD MMMM, YYYY');
  const isNightly = code === LINE_ITEM_NIGHT;
  const isDaily = code === LINE_ITEM_DAY;
  const isHourly = code === LINE_ITEM_HOUR;
  const translationKey = isNightly
    ? 'OrderBreakdown.baseUnitNight'
    : isDaily
      ? 'OrderBreakdown.baseUnitDay'
      : isHourly
        ? 'OrderBreakdown.baseUnitHour'
        : 'OrderBreakdown.baseUnitQuantity';

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === code && !item.reversal);

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;
  const newDate = moment().format('DD MMMM, YYYY');
  const bidprice = parseFloat(unitPrice.replace('$', '').replace(',', ''));
  const Depositfee = (bidprice * 20) / 100;
  const depostfeeMarketfee = (Depositfee * 18) / 100
  const depostprocessingfee = (Depositfee * 2.9) / 100
  const chargedepostprocessingfee = depostprocessingfee + 0.30
  const lastPayment = bidprice - Depositfee
  const lastPaymentmarketfee = (lastPayment * 18) / 100
  const lastprocessingfee = (lastPayment * 2.9) / 100
  // const chargelastprocessingfee = lastprocessingfee + 0.30
  const totalcharged = Depositfee + depostfeeMarketfee + chargedepostprocessingfee + lastPayment + lastprocessingfee + lastPaymentmarketfee
  // const total = Math.ceil(totalcharged)
  return quantity && total ? (
    <>
      <div>
        <div className={css.lineItemGroups}>
          <div className={css.lineItem}>
            <span className={css.itemLabel}>
              Deposit
              {/* <FormattedMessage id={translationKey} values={{ unitPrice }} /> */}
            </span>
            <span className={css.itemValue}>${Depositfee.toFixed(2)}</span>

          </div>
          <div className={css.lineItem}>

            <span className={css.itemLabel}>
              Due Date
              {/* <FormattedMessage id={translationKey} values={{ unitPrice }} /> */}
            </span>
            <span className={css.itemValue}>{newDate}</span>

          </div>
          <div className={css.lineItem}>

            <span className={css.itemLabel}>
              18% Marketplace fee *
              {/* <FormattedMessage id={translationKey} values={{ unitPrice }} /> */}
            </span>
            <span className={css.itemValue}>${depostfeeMarketfee.toFixed(2)}</span>

          </div>
          <div className={css.lineItem}>

            <span className={css.itemLabel}>
              2.9% + $0.30 transaction fee
              {/* <FormattedMessage id={translationKey} values={{ unitPrice }} /> */}
            </span>
            <span className={css.itemValue}>${chargedepostprocessingfee.toFixed(2)}</span>

          </div>
        </div>
      </div>
      <div className={css.lineItemGroups}>
        <div className={css.lineItem}>
          <span className={css.itemLabel}>
            Last Payment
            {/* <FormattedMessage id={translationKey} values={{ unitPrice }} /> */}
          </span>
          <span className={css.itemValue}>${lastPayment.toFixed(2)}</span>

        </div>
        <div className={css.lineItem}>

          <span className={css.itemLabel}>
            Due Date
            {/* <FormattedMessage id={translationKey} values={{ unitPrice }} /> */}
          </span>
          <span className={css.itemValue}>{formattedDate}</span>

        </div>
        <div className={css.lineItem}>

          <span className={css.itemLabel}>
            Marketplace fee *
            {/* <FormattedMessage id={translationKey} values={{ unitPrice }} /> */}
          </span>
          <span className={css.itemValue}>${lastPaymentmarketfee.toFixed(2)}</span>

        </div>
        <div className={css.lineItem}>

          <span className={css.itemLabel}>
            2.9%  transaction fee
            {/* <FormattedMessage id={translationKey} values={{ unitPrice }} /> */}
          </span>
          <span className={css.itemValue}>${lastprocessingfee.toFixed(2)}</span>

        </div>
      </div>
      {/* <div className={css.lineItem}>

        <span className={css.itemLabel}>
          Total
         
        </span>
        <span className={css.itemValue}>${totalcharged.toFixed(2)}</span>

      </div> */}
    </>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
