/////////////////////////////////////////////////////////
// Configurations related to listing.                  //
// Main configuration here is the extended data config //
/////////////////////////////////////////////////////////

// Note: The listingFields come from listingFields asset nowadays by default.
//       To use this built-in configuration, you need to change the overwrite from configHelper.js
//       (E.g. use mergeDefaultTypesAndFieldsForDebugging func)

/**
 * Configuration options for listing fields (custom extended data fields):
 * - key:                           Unique key for the extended data field.
 * - scope (optional):              Scope of the extended data can be either 'public' or 'private'.
 *                                  Default value: 'public'.
 *                                  Note: listing doesn't support 'protected' scope atm.
 * - schemaType (optional):         Schema for this extended data field.
 *                                  This is relevant when rendering components and querying listings.
 *                                  Possible values: 'enum', 'multi-enum', 'text', 'long', 'boolean'.
 * - enumOptions (optional):        Options shown for 'enum' and 'multi-enum' extended data.
 *                                  These are used to render options for inputs and filters on
 *                                  EditListingPage, ListingPage, and SearchPage.
 * - listingTypeConfig (optional):  Relationship configuration against listing types.
 *   - limitToListingTypeIds:         Indicator whether this listing field is relevant to a limited set of listing types.
 *   - listingTypeIds:                An array of listing types, for which this custom listing field is
 *                                    relevant and should be added. This is mandatory if limitToListingTypeIds is true.
 * - categoryConfig (optional):     Relationship configuration against categories.
 *   - limitToCategoryIds:            Indicator whether this listing field is relevant to a limited set of categories.
 *   - categoryIds:                   An array of categories, for which this custom listing field is
 *                                    relevant and should be added. This is mandatory if limitToCategoryIds is true.
 * - filterConfig:                  Filter configuration for listings query.
 *    - indexForSearch (optional):    If set as true, it is assumed that the extended data key has
 *                                    search index in place. I.e. the key can be used to filter
 *                                    listing queries (then scope needs to be 'public').
 *                                    Note: Flex CLI can be used to set search index for the key:
 *                                    https://www.sharetribe.com/docs/references/extended-data/#search-schema
 *                                    Read more about filtering listings with public data keys from API Reference:
 *                                    https://www.sharetribe.com/api-reference/marketplace.html#extended-data-filtering
 *                                    Default value: false,
 *   - filterType:                    Sometimes a single schemaType can be rendered with different filter components.
 *                                    For 'enum' schema, filterType can be 'SelectSingleFilter' or 'SelectMultipleFilter'
 *   - label:                         Label for the filter, if the field can be used as query filter
 *   - searchMode (optional):         Search mode for indexed data with multi-enum schema.
 *                                    Possible values: 'has_all' or 'has_any'.
 *   - group:                         SearchPageWithMap has grouped filters. Possible values: 'primary' or 'secondary'.
 * - showConfig:                    Configuration for rendering listing. (How the field should be shown.)
 *   - label:                         Label for the saved data.
 *   - isDetail                       Can be used to hide detail row (of type enum, boolean, or long) from listing page.
 *                                    Default value: true,
 * - saveConfig:                    Configuration for adding and modifying extended data fields.
 *   - label:                         Label for the input field.
 *   - placeholderMessage (optional): Default message for user input.
 *   - isRequired (optional):         Is the field required for providers to fill
 *   - requiredMessage (optional):    Message for those fields, which are mandatory.
 */
///Architecture, Engineering & Construction
export const options = [
  {
    value: 'construction',
    label: 'Construction',
  },
  {
    value: 'architecture',
    label: 'Architecture',
  },
  {
    value: 'engineering',
    label: 'Engineering',
  },
];
export const SpokenLanguages = [
  {
    value: ' spanish',
    label: ' Spanish',
  },
  {
    value: 'english',
    label: 'English',
  },
  {
    value: 'hindi',
    label: 'Hindi',
  },
  {
    value: 'german',
    label: 'German',
  },
  {
    value: 'portuguese',
    label: 'Portuguese',
  },
  {
    value: 'french',
    label: 'French',
  },
];
export const Disciplines = [
  {
    value: 'fireProtection',
    label: 'Fire Protection',
  },
  {
    value: 'architectural',
    label: 'Architectural',
  },
  {
    value: 'structural',
    label: 'Structural',
  },
  {
    value: 'mechanical',
    label: 'Mechanical',
  },
  {
    value: 'electrical',
    label: 'Electrical',
  },
  {
    value: 'plumbing',
    label: 'Plumbing',
  },
  {
    value: 'landscape',
    label: 'Landscape',
  },
  {
    value: 'civil',
    label: 'Civil',
  },
  {
    value: 'interiorDesign',
    label: 'Interior Design',
  },
  {
    value: 'other',
    label: 'Other',
  },
];
export const ProSoftwareTools = [
  {
    value: 'autodeskRevit',
    label: 'Autodesk Revit',
  },
  {
    value: '3DBIMDesignTools',
    label: '3D BIM Design Tools',
  },
  {
    value: 'microStation',
    label: 'MicroStation',
  },
  {
    value: 'vectorworks',
    label: 'Vectorworks',
  },
  {
    value: 'rhino',
    label: 'Rhino',
  },
  {
    value: 'vRay',
    label: 'V-Ray',
  },
  {
    value: 'blender',
    label: 'Blender',
  },
  {
    value: 'lumion',
    label: 'Lumion',
  },
  {
    value: 'solidworks',
    label: 'Solidworks',
  },
  {
    value: 'snaptrude',
    label: 'Snaptrude (emerging tech)',
  },
  {
    value: 'cadDesignTools',
    label: '2D CAD Design Tools',
  },
  {
    value: 'quantityTakeoffsTools',
    label: 'Quantity Takeoffs Tools',
  },
  {
    value: 'submittalManagementTools',
    label: 'RFI, COR and Submittal Management Tools',
  },
];

export const ProSoftwareToolsSubcategory = [
  { parentCategory: '3DBIMDesignTools', value: 'autodeskRevit', label: 'Autodesk Revit' },
  { parentCategory: '3DBIMDesignTools', value: 'archiCAD', label: 'ArchiCAD' },
  { parentCategory: '3DBIMDesignTools', value: 'autodesk3dsMax', label: 'Autodesk 3ds Max' },
  { parentCategory: '3DBIMDesignTools', value: 'sketchUp', label: 'Sketch Up' },
  { parentCategory: '3DBIMDesignTools', value: 'teklaStructures', label: 'Tekla Structures' },
  { parentCategory: '3DBIMDesignTools', value: 'autodeskCivil3D', label: 'Autodesk Civil 3D' },
  { parentCategory: '3DBIMDesignTools', value: 'autodeskNavisworks', label: 'Autodesk Navisworks' },
  { parentCategory: '3DBIMDesignTools', value: 'autoCAD', label: 'AutoCAD' },
  { parentCategory: '3DBIMDesignTools', value: 'rayon', label: 'Rayon (emerging tech)' },

  { parentCategory: 'cadDesignTools', value: 'archiCAD', label: 'ArchiCAD' },
  { parentCategory: 'cadDesignTools', value: 'sketchUp', label: 'Sketch Up' },
  { parentCategory: 'cadDesignTools', value: 'autoCAD', label: 'AutoCAD' },
  { parentCategory: 'cadDesignTools', value: 'rayon', label: 'Rayon (emerging tech)' },

  { parentCategory: 'quantityTakeoffsTools', value: 'planSwift', label: 'PlanSwift' },
  { parentCategory: 'quantityTakeoffsTools', value: 'bluebeamRevu', label: 'Bluebeam Revu' },
  {
    parentCategory: 'quantityTakeoffsTools',
    value: 'autodeskQuantityTakeoff',
    label: 'Autodesk Quantity Takeoff',
  },
  { parentCategory: 'quantityTakeoffsTools', value: 'onScreenTakeoff', label: 'On Screen Takeoff' },

  { parentCategory: 'submittalManagementTools', value: 'procore', label: 'Procore' },
  { parentCategory: 'submittalManagementTools', value: 'planGrid', label: 'PlanGrid' },
  { parentCategory: 'submittalManagementTools', value: 'cmic', label: 'CMiC' },
  { parentCategory: 'submittalManagementTools', value: 'aconex', label: 'Aconex' },
  { parentCategory: 'submittalManagementTools', value: 'buildertrend', label: 'Buildertrend' },
];

export const subDBIMDesignTools = [
  {
    value: 'autodeskRevit',
    label: 'Autodesk Revit',
  },
  {
    value: 'archiCAD',
    label: 'ArchiCAD',
  },
  {
    value: 'autodesk3dsMax',
    label: 'Autodesk 3ds Max',
  },
  {
    value: 'sketchUp',
    label: 'Sketch Up',
  },
  {
    value: 'teklaStructures',
    label: 'Tekla Structures',
  },
  {
    value: 'autodeskCivil3D',
    label: 'Autodesk Civil 3D',
  },
  {
    value: 'autodeskNavisworks',
    label: 'Autodesk Navisworks',
  },
  {
    value: 'autoCAD',
    label: 'AutoCAD',
  },
  {
    value: 'rayon',
    label: 'Rayon (emerging tech)',
  },
];
export const cadDesignTools = [
  {
    value: 'archiCAD',
    label: 'ArchiCAD',
  },
  {
    value: 'sketchUp',
    label: 'Sketch Up',
  },
  {
    value: ' autoCAD',
    label: ' AutoCAD',
  },
  {
    value: 'rayon',
    label: 'Rayon (emerging tech)',
  },
];

export const quantityTakeoffsTools = [
  {
    value: 'planSwift',
    label: 'PlanSwift',
  },
  {
    value: 'bluebeamRevu',
    label: 'Bluebeam Revu',
  },
  {
    value: 'autodeskQuantityTakeoff',
    label: 'Autodesk Quantity Takeoff',
  },
  {
    value: 'onScreenTakeoff',
    label: 'On Screen Takeoff',
  },
];
export const submittalManagementTools = [
  {
    value: 'procore',
    label: 'Procore',
  },
  {
    value: 'planGrid',
    label: 'PlanGrid',
  },
  {
    value: 'cmic',
    label: 'CMiC',
  },
  {
    value: ' aconex',
    label: ' Aconex',
  },
  {
    value: 'buildertrend',
    label: 'Buildertrend',
  },
];

export const RequiredPros = [
  {
    value: '3DDesign',
    label: '3D Design',
    url: 'https://res.cloudinary.com/dic6op0in/image/upload/v1723813064/catgoryImages/Group_1410087859_ltpnbh.png'
  },
  {
    value: '2DDesign',
    label: '2D Design',
    url: 'https://res.cloudinary.com/dic6op0in/image/upload/v1723813064/catgoryImages/Group_1410087858_beztfz.png'
  },
  {
    value: 'estimates/MaterialTakeoff',
    label: 'Estimates/Material Takeoff',
    url: 'https://res.cloudinary.com/dic6op0in/image/upload/v1723813064/catgoryImages/Group_1410087857_vp9rbl.png'
  },
  {
    value: 'submittals',
    label: 'Submittals',
    url: 'https://res.cloudinary.com/dic6op0in/image/upload/v1723813065/catgoryImages/Group_1410087862_h15ucw.png'
  },
  {
    value: 'procurement',
    label: 'Procurement',
    url: 'https://res.cloudinary.com/dic6op0in/image/upload/v1723813064/catgoryImages/Group_1410087860_dx5piu.png'
  },
  {
    value: 'other',
    label: 'Other',
    url: 'https://res.cloudinary.com/dic6op0in/image/upload/v1723813064/catgoryImages/Group_1410087861_k64zk2.png'
  },
];

export const specifyDiscipline = [
  {
    value: 'fireProtection',
    label: 'Fire Protection',
  },
  {
    value: 'architectural',
    label: 'Architectural',
  },
  {
    value: 'structural',
    label: 'Structural',
  },
  {
    value: 'mechanical',
    label: 'Mechanical',
  },
  {
    value: 'electrical',
    label: 'Electrical',
  },
  {
    value: 'plumbing',
    label: 'Plumbing',
  },
  {
    value: 'landscape',
    label: 'Landscape',
  },
  {
    value: 'civil',
    label: 'Civil',
  },
  {
    value: 'interiorDesign',
    label: 'Interior Design',
  },
  {
    value: 'other',
    label: 'Other',
  },
];
export const chooseuploadfile = [
  { key: 'uploadimagePdf', option: 'uploadimagePdf', label: 'Upload Image and PDF' },
  { key: 'uploadfile', option: 'uploadfile', label: 'upload file' },
];
export const chooseProgress = [
  { key: 'uploadimagePdf', option: 'uploadimagePdf', label: 'Upload Image and PDF' },
  { key: 'uploadfile', option: 'uploadfile', label: 'upload file' },
];
export const IdealTimeframe = [
  {
    value: '3Days',
    label: 'Under a Week',
  },
  {
    value: '1Week',
    label: '1 Week',
  },
  {
    value: '1Month',
    label: '1 Month',
  },
  {
    value: '2Months',
    label: '2 Months',
  },
  {
    value: '3Months',
    label: '3 Months',
  },
  {
    value: '6Months',
    label: '6 Months',
  },
];
export const ProjectProgressStatus = [
  {
    value: 'inProgress',
    label: 'In Progress',
  },
  {
    value: 'blocked',
    label: 'Blocked',
  },
  {
    value: 'revisionSubmitted',
    label: 'Revision Submitted',
  },
  {
    value: 'finalRevisionSubmitted',
    label: 'Final Revision Submitted',
  },
  {
    value: 'done',
    label: 'Done',
  }
];
export const BudgetRange = [
  {
    value: '$1000',
    label: 'Under $1000',
    minValue: 0,
    maxValue: 999,
  },
  {
    value: '$1000-$3000',
    label: '$1000-$3000',
    minValue: 999,
    maxValue: 3000,
  },
  {
    value: '$3000- $5000',
    label: '$3000- $5000',
    minValue: 2999,
    maxValue: 5000,
  },
  {
    value: '$5000-$10,000',
    label: '$5000-$10,000',
    minValue: 4999,
    maxValue: 10000,
  },
  {
    value: '$10,000+',
    label: 'Over $10,000',
    minValue: 10000,
    maxValue: 99999,
  },
];

const industrySubCategories = [
  {
    label: 'Architectural Modeling',
    value:
      'Optimize your budget with accurate material estimation services, ensuring cost-efficiency and resource management for construction projects.',
  },
  {
    label: 'Structural Analysis',
    value:
      'Leverage advanced 3D BIM for structural analysis to enhance the safety and durability of construction projects.',
  },
  {
    label: 'Drafting Services',
    value:
      'Professional 2D CAD drafting services bring your construction projects to life with detailed accuracy and expert planning.',
  },
  {
    label: 'Technical Drawings/Shop Drawings',
    value:
      'Get access to expertly crafted technical drawings, elevating construction project planning with 2D CAD precision.',
  },
  {
    label: 'Material Estimation',
    value:
      'Optimize your budget with accurate material estimation services, ensuring cost-efficiency and resource management for construction projects.',
  },
  {
    label: 'Quantity Surveying',
    value:
      'Expert quantity surveying services for construction projects offer detailed counters and takeoff solutions, ensuring accurate budgeting and resource allocation.',
  },
  {
    label: 'Documentation Management',
    value:
      'Streamline your construction project with top-tier documentation management services, enhancing efficiency and compliance through organized submittals.',
  },
  {
    label: 'Approval Processes',
    value:
      'Simplify the approval process with specialized submittal services, designed to expedite project timelines and ensure compliance with construction standards.',
  },
  {
    label: 'Procurement Agent',
    value:
      'Optimize your construction project outcomes with expert procurement services, ensuring timely delivery and cost-effectiveness through strategic sourcing and supplier management.',
  },
  {
    label: 'Procurement Collector',
    value:
      'Streamline your construction schedule with our skilled procurement collector, efficiently managing subcontractor timelines for optimal project progression.',
  },
  {
    label: '4D BIM',
    value:
      'Elevate your construction project management with our advanced 4D BIM services, integrating time-related data with building models for enhanced scheduling and real-time progress tracking.',
  },
  {
    label: '5D BIM',
    value:
      "Maximize your construction project's budget efficiency with our 5D BIM services, seamlessly integrating cost estimation into the building model for accurate financial planning and management.",
  },
  {
    label: 'Owner Rep',
    value:
      'Ensure your project aligns with your vision and standards with our Owner’s Representative service, expertly handling the review of change orders, RFIs, and submittals to protect your best interests.',
  },
];

export const PaymentPlanlessthen1month = [
  {
    value: '20%UpfrontDeposit',
    label: '20% Upfront Deposit + Balance',
  },
];
export const PaymentPlan = [
  {
    value: '20%UpfrontDeposit',
    label: '20% Upfront Deposit + Balance ',
  },
  {
    value: '2',
    label: ' 20% Deposit + 2 Payments ',
  },
  {
    value: '3',
    label: '20% Deposit + 3 Payments',
  },
];

export const listingFields = [
  // {
  //   key: 'bikeType',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'city-bikes', label: 'City bikes' },
  //     { option: 'electric-bikes', label: 'Electric bikes' },
  //     { option: 'mountain-bikes', label: 'Mountain bikes' },
  //     { option: 'childrens-bikes', label: "Children's bikes" },
  //   ],
  //   categoryConfig: {
  //     limitToCategoryIds: true,
  //     categoryIds: ['cats'],
  //   },
  //   filterConfig: {
  //     indexForSearch: true,
  //     filterType: 'SelectMultipleFilter', //'SelectSingleFilter',
  //     label: 'Bike type',
  //     group: 'primary',
  //   },
  //   showConfig: {
  //     label: 'Bike type',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Bike type',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a bike type.',
  //   },
  // },
  // {
  //   key: 'tire',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: '29', label: '29' },
  //     { option: '28', label: '28' },
  //     { option: '27', label: '27' },
  //     { option: '26', label: '26' },
  //     { option: '24', label: '24' },
  //     { option: '20', label: '20' },
  //     { option: '18', label: '18' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Tire size',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Tire size',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Tire size',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a tire size.',
  //   },
  // },
  // {
  //   key: 'brand',
  //   scope: 'public',
  //   schemaType: 'enum',
  //   enumOptions: [
  //     { option: 'cube', label: 'Cube' },
  //     { option: 'diamant', label: 'Diamant' },
  //     { option: 'ghost', label: 'GHOST' },
  //     { option: 'giant', label: 'Giant' },
  //     { option: 'kalkhoff', label: 'Kalkhoff' },
  //     { option: 'kona', label: 'Kona' },
  //     { option: 'otler', label: 'Otler' },
  //     { option: 'vermont', label: 'Vermont' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Brand',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Brand',
  //     isDetail: true,
  //   },
  //   saveConfig: {
  //     label: 'Brand',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: true,
  //     requiredMessage: 'You need to select a brand.',
  //   },
  // },
  // {
  //   key: 'accessories',
  //   scope: 'public',
  //   schemaType: 'multi-enum',
  //   enumOptions: [
  //     { option: 'bell', label: 'Bell' },
  //     { option: 'lights', label: 'Lights' },
  //     { option: 'lock', label: 'Lock' },
  //     { option: 'mudguard', label: 'Mudguard' },
  //   ],
  //   filterConfig: {
  //     indexForSearch: true,
  //     label: 'Accessories',
  //     searchMode: 'has_all',
  //     group: 'secondary',
  //   },
  //   showConfig: {
  //     label: 'Accessories',
  //   },
  //   saveConfig: {
  //     label: 'Accessories',
  //     placeholderMessage: 'Select an option…',
  //     isRequired: false,
  //   },
  // },
  // // An example of how to use transaction type specific custom fields and private data.
  // {
  //   key: 'note',
  //   scope: 'public',
  //   schemaType: 'text',
  //   listingTypeConfig: {
  //     limitToListingTypeIds: true,
  //     listingTypeIds: ['product-selling'],
  //   },
  //   showConfig: {
  //     label: 'Extra notes',
  //   },
  //   saveConfig: {
  //     label: 'Extra notes',
  //     placeholderMessage: 'Some public extra note about this bike...',
  //   },
  // },
  // {
  //   key: 'privatenote',
  //   scope: 'private',
  //   schemaType: 'text',
  //   listingTypeConfig: {
  //     limitToListingTypeIds: true,
  //     listingTypeIds: ['daily-booking'],
  //   },
  //   saveConfig: {
  //     label: 'Private notes',
  //     placeholderMessage: 'Some private note about this bike...',
  //   },
  // },
];

///////////////////////////////////////////////////////////////////////
// Configurations related to listing types and transaction processes //
///////////////////////////////////////////////////////////////////////

// A presets of supported listing configurations
//
// Note 1: The listingTypes come from listingTypes asset nowadays by default.
//         To use this built-in configuration, you need to change the overwrite from configHelper.js
//         (E.g. use mergeDefaultTypesAndFieldsForDebugging func)
// Note 2: transaction type is part of listing type. It defines what transaction process and units
//         are used when transaction is created against a specific listing.

/**
 * Configuration options for listing experience:
 * - listingType:         Unique string. This will be saved to listing's public data on
 *                        EditListingWizard.
 * - label                Label for the listing type. Used as microcopy for options to select
 *                        listing type in EditListingWizard.
 * - transactionType      Set of configurations how this listing type will behave when transaction is
 *                        created.
 *   - process              Transaction process.
 *                          The process must match one of the processes that this client app can handle
 *                          (check src/util/transactions/transaction.js) and the process must also exists in correct
 *                          marketplace environment.
 *   - alias                Valid alias for the aforementioned process. This will be saved to listing's
 *                          public data as transctionProcessAlias and transaction is initiated with this.
 *   - unitType             Unit type is mainly used as pricing unit. This will be saved to
 *                          transaction's protected data.
 *                          Recommendation: don't use same unit types in completely different processes
 *                          ('item' sold should not be priced the same as 'item' booked).
 * - stockType            This is relevant only to listings using default-purchase process.
 *                        If set to 'oneItem', stock management is not showed and the listing is
 *                        considered unique (stock = 1).
 *                        Possible values: 'oneItem' and 'multipleItems'.
 *                        Default: 'multipleItems'.
 * - defaultListingFields These are tied to transaction processes. Different processes have different flags.
 *                        E.g. default-inquiry can toggle price and location to true/false value to indicate,
 *                        whether price (or location) tab should be shown. If defaultListingFields.price is not
 *                        explicitly set to _false_, price will be shown.
 *                        If the location or pickup is not used, listing won't be returned with location search.
 *                        Use keyword search as main search type if location is not enforced.
 *                        The payoutDetails flag allows provider to bypass setting of payout details.
 *                        Note: customers can't order listings, if provider has not set payout details! Monitor
 *                        providers who have not set payout details and contact them to ensure that they add the details.
 */

export const listingTypes = [
  {
    listingType: 'daily-booking',
    label: 'Daily booking',
    transactionType: {
      process: 'default-booking',
      alias: 'default-booking/release-1',
      unitType: 'day',
    },
    defaultListingFields: {
      location: true,
      payoutDetails: true,
    },
  },
  // // Here are some examples for other listingTypes
  // // TODO: SearchPage does not work well if both booking and product selling are used at the same time
  // {
  //   listingType: 'nightly-booking',
  //   label: 'Nightly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'night',
  //   },
  // },
  // {
  //   listingType: 'hourly-booking',
  //   label: 'Hourly booking',
  //   transactionType: {
  //     process: 'default-booking',
  //     alias: 'default-booking/release-1',
  //     unitType: 'hour',
  //   },
  // },
  // {
  //   listingType: 'product-selling',
  //   label: 'Sell bicycles',
  //   transactionType: {
  //     process: 'default-purchase',
  //     alias: 'default-purchase/release-1',
  //     unitType: 'item',
  //   },
  //   stockType: 'multipleItems',
  //   defaultListingFields: {
  //     shipping: true,
  //     pickup: true,
  //     payoutDetails: true,
  //   },
  // },
  // {
  //   listingType: 'inquiry',
  //   label: 'Inquiry',
  //   transactionType: {
  //     process: 'default-inquiry',
  //     alias: 'default-inquiry/release-1',
  //     unitType: 'inquiry',
  //   },
  //   defaultListingFields: {
  //     price: false,
  //     location: true,
  //   },
  // },
];

// SearchPage can enforce listing query to only those listings with valid listingType
// However, it only works if you have set 'enum' type search schema for the public data fields
//   - listingType
//
//  Similar setup could be expanded to 2 other extended data fields:
//   - transactionProcessAlias
//   - unitType
//
// Read More:
// https://www.sharetribe.com/docs/how-to/manage-search-schemas-with-flex-cli/#adding-listing-search-schemas
export const enforceValidListingType = false;
