import React, { useState } from 'react';
import css from './JobsCards.module.css'
import Button from '../Button/Button';
import IconCollection from '../IconCollection/IconCollection';
import { getLabel } from '../../util/dataExtractor';
import { createSlug } from '../../util/urlHelpers';
import NamedLink from '../NamedLink/NamedLink';
import { Disciplines, IdealTimeframe, RequiredPros } from '../../config/configListing';
import classNames from 'classnames';
import ResponsiveImage from '../ResponsiveImage/ResponsiveImage';
const JobsCards = ({ listings, currentUser, isAuthenticated }) => {
    const [showFullDescription, setShowFullDescription] = useState(false);
    return (
        <div className={css.jobsCardWrapper}>
            <div className={css.jobCards}>
                {listings && listings.map((job) => (
                    <NamedLink
                        name={"ListingPage"}
                        params={{ id: job && job.id && job.id.uuid, slug: createSlug(job?.attributes?.title) }}
                        className={css.link}
                        isSearchPage={true}
                    >
                        <div className={classNames(css.jobCard, isAuthenticated ? null : css.blurCard)}>
                            <div className={css.cardImage}>
                                <div>
                                    {job?.attributes?.publicData?.cloudinaryUrls?.length > 0 ? (
                                        <div className={css.cardImageBox}>
                                            <ResponsiveImage
                                                url={job?.attributes?.publicData?.cloudinaryUrls[0]?.secure_url} alt="listing-img" />
                                        </div>
                                    ) : (
                                        <>
                                            {

                                                getLabel(RequiredPros, job?.attributes?.publicData?.requiredPros, 'pro') && getLabel(RequiredPros, job?.attributes?.publicData?.requiredPros, 'pro').url
                                                    ?
                                                    <div className={css.cardImageBox}>
                                                        <ResponsiveImage url={getLabel(RequiredPros, job?.attributes?.publicData?.requiredPros, 'pro').url} alt="listing-img" />
                                                    </div>
                                                    :
                                                    <div className={css.cardImageBox}>
                                                        <IconCollection icon={'NoImage'} />{' '}
                                                    </div>
                                            }
                                        </>)}
                                </div>
                            </div>
                            <div className={css.categoryTitle}>
                                <span>{job?.attributes?.publicData?.requiredPros && getLabel(RequiredPros, job?.attributes?.publicData?.requiredPros)}</span>
                            </div>
                            <div className={css.cardContents}>
                                <div>
                                    <h3 className={css.title}>{job?.attributes?.title}</h3>
                                    <p onClick={() => setShowFullDescription(!showFullDescription)} className={css.description}>
                                        {showFullDescription ? (
                                            <span>{job?.attributes?.description}</span>
                                        ) : (
                                            <span>{job?.attributes?.description?.slice(0, 95)}</span>
                                        )}
                                        {(job?.attributes?.description?.length > 95) && <span className={css.readStatus} onClick={() => setShowFullDescription(!showFullDescription)}>
                                            {showFullDescription ? '...READ LESS' : '...READ MORE'}
                                        </span>}
                                    </p>
                                </div>
                                <div>
                                    <div className={css.skillContents}>
                                        <div className={css.skillContent}>
                                            {job?.attributes?.publicData?.specifyDiscipline && getLabel(Disciplines, job?.attributes?.publicData?.specifyDiscipline)}
                                        </div>
                                        {/* <div className={css.skillContent}>
                                            {job?.attributes?.publicData?.requiredPros && getLabel(RequiredPros, job?.attributes?.publicData?.requiredPros)}
                                        </div> */}
                                    </div>
                                    <div className={css.dividerLine}></div>
                                    <h6>{job?.attributes?.publicData?.budgetRange} | {job?.attributes?.publicData?.idealTimeframe && getLabel(IdealTimeframe, job?.attributes?.publicData?.idealTimeframe)
                                    }</h6>
                                </div>
                            </div>
                        </div>
                    </NamedLink>
                ))}

            </div>
            {isAuthenticated ? <NamedLink
                name={"SearchPage"}
                className={css.link}
                isSearchPage={true}
            >
                <Button className={css.joinButton}>Join Now & View More</Button>
            </NamedLink>
                :
                <NamedLink
                    name={"SignupPage"}
                    className={css.link}
                >
                    <Button className={css.joinButton}>Join Now & View More</Button>
                </NamedLink>}
        </div>

    );
};

export default JobsCards;