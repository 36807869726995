import phonesIcon from '../assets/categoryIcons/phones.png';
import computerIcon from '../assets/categoryIcons/computer.png';
import tvIcon from '../assets/categoryIcons/tv.png';
import headPhonesIcon from '../assets/categoryIcons/headPhones.png';
import watchesIcon from '../assets/categoryIcons/watches.png';
import clothingIcon from '../assets/categoryIcons/clothing1.png';
import {
  FREE_PLAN_COUNT_LIMIT,
  FREE_PLAN_PRICE_LIMIT,
  MEMBER_PLAN_COUNT_LIMIT,
  MEMBER_PLAN_PRICE_LIMIT,
  SUBSCRIPTION_MEMBER_PLAN,
} from './types';

export const freePlanDetails = [
  'Basic account features',
  ' Basic rewards',
  ' Payout 5 working days after delivery',
  ' 10% selling fees',
  'Individual',
];

export const additionalPlanDetails = [
  'Stoado verification service',
  'Sell up to £500 per month',
  'Sell up to 50 items per month',
];

export const memberPlanDetails = [
  'Enhanced account features',
  'Up to 10x better rewards',
  'Instant payouts',
  ' 8% selling fees',
  'Priority customer support',
  'Access to Cryptocurrency',
  '30 days free trial after sign up',
  'Individual or business',
];

export const memberPerks = [
  '1 free weekly spin',
  'Stoado verification service',
  'Chargeback Protection',
  ' Return Protection',
  'Sell up to £25,000 per month',
  'Sell up to 1000 items per month',
];

export const businessPlanDetails = [
  'Business account features',
  'Enhanced Rewards',
  'Instant payouts',
  '5% selling fees',
  'Cryptocurrency',
  'VIP customer support',
  '30 days free trial after sign up',
  'business only',
];

export const businessPerks = [
  'Enhanced Chargeback Protection',
  'Enhanced Return Protection',
  'No Selling Limits',
];

export function getFileExtension(filePath) {
  const extIndex = filePath.lastIndexOf('.');
  return extIndex > 0 ? filePath.substring(extIndex + 1) : '';
}

export const getUserDetails = user => {
  const fullName = user?.attributes?.profile?.publicData?.fullName;
  let profileImage = null;
  if (!!user?.id && user?.profileImage?.attributes?.variants) {
    if (user?.profileImage?.attributes?.variants?.default) {
      profileImage = user?.profileImage?.attributes?.variants?.default?.url;
    } else {
      profileImage = user?.profileImage?.attributes?.variants['square-small2x']?.url;
    }
  } else {
    profileImage = user?.attributes?.profile?.publicData?.picture;
  }
  const email =
    !!user?.id && user?.attributes?.email
      ? user?.attributes?.email
      : user?.attributes?.profile?.publicData?.email;
  const id = user?.id && user?.id?.uuid;
  const firstName =
    user?.attributes?.profile?.displayName && user?.attributes?.profile?.displayName.split(' ')[0];
  return {
    fullName,
    email,
    id,
    firstName,
  };
};

export const getLabel = (category, key, type) => {
  const cat = category.find(c => c.value === key);
  return type == 'pro'
    ? cat
    : cat
      ? cat.label
      : key;
};

export const popularCategories = [
  {
    label: 'Phones & Accessories',
    icon: phonesIcon,
    search: '?pub_subCategory=phones-accessories',
  },
  {
    label: 'Computers & Laptops',
    icon: computerIcon,
    search: '?pub_subCategory=computers-laptops',
  },
  { label: 'Clothing', icon: clothingIcon, search: '?pub_category=clothing' },
  { label: 'TV & Video', icon: tvIcon, search: '?pub_subCategory=tv-video' },
  { label: 'Watches', icon: watchesIcon, search: '?pub_category=watches' },
  {
    label: 'Headphones & MP3 Players',
    icon: headPhonesIcon,
    search: '?pub_subCategory=headphones-mP3-players',
  },
];

export const popularSubCategories = [
  {
    label: 'Phones & Accessories',
    key: 'phones-accessories',
    search: '?pub_subCategoryChild=phones-accessories',
  },
  {
    label: 'Computers & Laptops',
    key: 'computers-laptops',
    search: '?pub_subCategoryChild=computers-laptops',
  },
  {
    label: 'Men’s Clothing',
    key: 'mens-clothing',
    search: '?pub_subCategory=mens-clothing',
  },
  {
    label: ' Women’s Clothing',
    key: 'womens-clothing',
    search: '?pub_subCategory=womens-clothing',
  },
  { label: 'TV & Video', key: 'tv-video', search: '?pub_subCategory=tv-video' },
  { label: 'Watches', key: 'watches', search: '?pub_subCategory=watches' },
  {
    label: 'Headphones & MP3 Players',
    key: 'headphones-mP3-players',
    search: '?pub_subCategory=headphones-mP3-players',
  },
];

export const extractSoldStats = response => {
  const includedData = response.data.included;
  const listingsSoldCount =
    includedData[0]?.attributes?.profile?.publicData?.listingsSoldCount || 0;
  const listingsSoldPrice =
    includedData[0]?.attributes?.profile?.publicData?.listingsSoldPrice || 0;
  return { listingsSoldCount, listingsSoldPrice };
};

export const getTotalCount = (listingsSoldCount, newListingCount) => {
  return newListingCount ? listingsSoldCount + newListingCount : listingsSoldCount + 1 || 0;
};

export const getTotalPrice = (listingsSoldPrice, newPrice) => {
  return listingsSoldPrice + newPrice;
};

export const checkPriceLimit = (totalPrice, freePlanData, currentPlanData) => {
  const isExceedPriceLimit =
    (totalPrice >= FREE_PLAN_PRICE_LIMIT && freePlanData?.isFreePlanActive) ||
    (totalPrice >= MEMBER_PLAN_PRICE_LIMIT &&
      currentPlanData?.planName === SUBSCRIPTION_MEMBER_PLAN);
  return isExceedPriceLimit;
};

export const checkCountLimit = (totalCount, freePlanData, currentPlanData) => {
  const isExceedCountLimit =
    (totalCount === FREE_PLAN_COUNT_LIMIT && freePlanData?.isFreePlanActive) ||
    (totalCount === 13 && currentPlanData?.planName === SUBSCRIPTION_MEMBER_PLAN);
  return isExceedCountLimit;
};

export const filterCartItems = (cartItems, listingId) => {
  return cartItems && cartItems.filter(item => !listingId.includes(item.listingId));
};
