import { denormalisedResponseEntities } from '../../util/data';
import { storableError } from '../../util/errors';
import { currentUserShowSuccess } from '../../ducks/user.duck';
import { createAlgoliaData, updateAlgoliaData } from '../../util/api';

// ================ Action types ================ //

export const CLEAR_UPDATED_FORM = 'app/ProfessionalProfileSettingsPage/CLEAR_UPDATED_FORM';

export const UPLOAD_IMAGE_REQUEST = 'app/ProfessionalProfileSettingsPage/UPLOAD_IMAGE_REQUEST';
export const UPLOAD_IMAGE_SUCCESS = 'app/ProfessionalProfileSettingsPage/UPLOAD_IMAGE_SUCCESS';
export const UPLOAD_IMAGE_ERROR = 'app/ProfessionalProfileSettingsPage/UPLOAD_IMAGE_ERROR';

export const UPDATE_PROFILE_REQUEST = 'app/ProfessionalProfileSettingsPage/UPDATE_PROFILE_REQUEST';
export const UPDATE_PROFILE_SUCCESS = 'app/ProfessionalProfileSettingsPage/UPDATE_PROFILE_SUCCESS';
export const UPDATE_PROFILE_ERROR = 'app/ProfessionalProfileSettingsPage/UPDATE_PROFILE_ERROR';

export const CREATE_PRO_LISTING_REQUEST = 'app/ProfessionalProfileSettingsPage/CREATE_PRO_LISTING_REQUEST';
export const CREATE_PRO_LISTING_SUCCESS = 'app/ProfessionalProfileSettingsPage/CREATE_PRO_LISTING_SUCCESS';
export const CREATE_PRO_LISTING_ERROR = 'app/ProfessionalProfileSettingsPage/CREATE_PRO_LISTING_ERROR';

export const UPDATE_PRO_LISTING_REQUEST = 'app/ProfessionalProfileSettingsPage/UPDATE_PRO_LISTING_REQUEST';
export const UPDATE_PRO_LISTING_SUCCESS = 'app/ProfessionalProfileSettingsPage/UPDATE_PRO_LISTING_SUCCESS';
export const UPDATE_PRO_LISTING_ERROR = 'app/ProfessionalProfileSettingsPage/UPDATE_PRO_LISTING_ERROR';

export const QUERY_PRO_LISTING_REQUEST = 'app/ProfessionalProfileSettingsPage/QUERY_PRO_LISTING_REQUEST';
export const QUERY_PRO_LISTING_SUCCESS = 'app/ProfessionalProfileSettingsPage/QUERY_PRO_LISTING_SUCCESS';
export const QUERY_PRO_LISTING_ERROR = 'app/ProfessionalProfileSettingsPage/QUERY_PRO_LISTING_ERROR';

// ================ Reducer ================ //

const initialState = {
  image: null,
  uploadImageError: null,
  uploadInProgress: false,
  updateInProgress: false,
  updateProfileError: null,
  listings: [],
  listingInProgress: false,
  listingError: null
};

export default function reducer(state = initialState, action = {}) {
  const { type, payload } = action;
  switch (type) {
    case UPLOAD_IMAGE_REQUEST:
      // payload.params: { id: 'tempId', file }
      return {
        ...state,
        image: { ...payload.params },
        uploadInProgress: true,
        uploadImageError: null,
      };
    case UPLOAD_IMAGE_SUCCESS: {
      // payload: { id: 'tempId', uploadedImage }
      const { id, uploadedImage } = payload;
      const { file } = state.image || {};
      const image = { id, imageId: uploadedImage.id, file, uploadedImage };
      return { ...state, image, uploadInProgress: false };
    }
    case UPLOAD_IMAGE_ERROR: {
      // eslint-disable-next-line no-console
      return { ...state, image: null, uploadInProgress: false, uploadImageError: payload.error };
    }

    case UPDATE_PROFILE_REQUEST:
      return {
        ...state,
        updateInProgress: true,
        updateProfileError: null,
      };
    case UPDATE_PROFILE_SUCCESS:
      return {
        ...state,
        image: null,
        updateInProgress: false,
      };
    case UPDATE_PROFILE_ERROR:
      return {
        ...state,
        image: null,
        updateInProgress: false,
        updateProfileError: payload,
      };

    case CREATE_PRO_LISTING_REQUEST:
      return {
        ...state,
        listingInProgress: true,
        listingError: null,
      };
    case CREATE_PRO_LISTING_SUCCESS:
      return {
        ...state,
        listings: payload,
        listingInProgress: false,
      };
    case CREATE_PRO_LISTING_ERROR:
      return {
        ...state,
        listings: null,
        listingInProgress: false,
        listingError: payload,
      };
    case UPDATE_PRO_LISTING_REQUEST:
      return {
        ...state,
        listingInProgress: true,
        listingError: null,
      };
    case UPDATE_PRO_LISTING_SUCCESS:
      return {
        ...state,
        listings: payload,
        listingInProgress: false,
      };
    case UPDATE_PRO_LISTING_ERROR:
      return {
        ...state,
        listings: null,
        listingInProgress: false,
        listingError: payload,
      };
    case QUERY_PRO_LISTING_REQUEST:
      return {
        ...state,
        listingInProgress: true,
        listingError: null,
      };
    case QUERY_PRO_LISTING_SUCCESS:
      return {
        ...state,
        listings: payload,
        listingInProgress: false,
      };
    case QUERY_PRO_LISTING_ERROR:
      return {
        ...state,
        listings: null,
        listingInProgress: false,
        listingError: payload,
      };

    case CLEAR_UPDATED_FORM:
      return { ...state, updateProfileError: null, uploadImageError: null };

    default:
      return state;
  }
}

// ================ Selectors ================ //

// ================ Action creators ================ //

export const clearUpdatedForm = () => ({
  type: CLEAR_UPDATED_FORM,
});

// SDK method: images.upload
export const uploadImageRequest = params => ({ type: UPLOAD_IMAGE_REQUEST, payload: { params } });
export const uploadImageSuccess = result => ({ type: UPLOAD_IMAGE_SUCCESS, payload: result.data });
export const uploadImageError = error => ({
  type: UPLOAD_IMAGE_ERROR,
  payload: error,
  error: true,
});

// SDK method: sdk.currentUser.updateProfile
export const updateProfileRequest = params => ({
  type: UPDATE_PROFILE_REQUEST,
  payload: { params },
});
export const updateProfileSuccess = result => ({
  type: UPDATE_PROFILE_SUCCESS,
  payload: result.data,
});
export const updateProfileError = error => ({
  type: UPDATE_PROFILE_ERROR,
  payload: error,
  error: true,
});

// SDK method: sdk.ownListing.create
export const createProListingRequest = params => ({
  type: CREATE_PRO_LISTING_REQUEST,
  payload: { params },
});
export const createProListingSuccess = result => ({
  type: CREATE_PRO_LISTING_SUCCESS,
  payload: [result?.data?.data],
});
export const createProListingError = error => ({
  type: CREATE_PRO_LISTING_ERROR,
  payload: error,
  error: true,
});

// SDK method: sdk.ownListing.update
export const updateProListingRequest = params => ({
  type: UPDATE_PRO_LISTING_REQUEST,
  payload: { params },
});
export const updateProListingSuccess = result => ({
  type: UPDATE_PRO_LISTING_SUCCESS,
  payload: [result?.data?.data],
});
export const updateProListingError = error => ({
  type: UPDATE_PRO_LISTING_ERROR,
  payload: error,
  error: true,
});

// SDK method: sdk.ownListing.query
export const queryProListingRequest = params => ({
  type: QUERY_PRO_LISTING_REQUEST,
  payload: { params },
});
export const queryProListingSuccess = result => ({
  type: QUERY_PRO_LISTING_SUCCESS,
  payload: result.data.data,
});
export const queryProListingError = error => ({
  type: QUERY_PRO_LISTING_ERROR,
  payload: error,
  error: true,
});

// ================ Thunk ================ //

// Images return imageId which we need to map with previously generated temporary id
export function uploadImage(actionPayload) {
  return (dispatch, getState, sdk) => {
    const id = actionPayload.id;
    dispatch(uploadImageRequest(actionPayload));

    const bodyParams = {
      image: actionPayload.file,
    };
    const queryParams = {
      expand: true,
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.images
      .upload(bodyParams, queryParams)
      .then(resp => {
        const uploadedImage = resp.data.data;
        dispatch(uploadImageSuccess({ data: { id, uploadedImage } }));
      })
      .catch(e => dispatch(uploadImageError({ id, error: storableError(e) })));
  };
}

export const updateProfile = actionPayload => {
  return (dispatch, getState, sdk) => {
    dispatch(updateProfileRequest());

    const queryParams = {
      expand: true,
      include: ['profileImage'],
      'fields.image': ['variants.square-small', 'variants.square-small2x'],
    };

    return sdk.currentUser
      .updateProfile(actionPayload, queryParams)
      .then(response => {
        dispatch(updateProfileSuccess(response));

        const entities = denormalisedResponseEntities(response);
        if (entities.length !== 1) {
          throw new Error('Expected a resource in the sdk.currentUser.updateProfile response');
        }
        const currentUser = entities[0];

        // Update current user in state.user.currentUser through user.duck.js
        dispatch(currentUserShowSuccess(currentUser));
      })
      .catch(e => dispatch(updateProfileError(storableError(e))));
  };
};

export const createProListing = actionPayload => {
  return (dispatch, getState, sdk) => {
    dispatch(createProListingRequest());

    return sdk.ownListings
      .create(actionPayload, { expand: true })
      .then(response => {
        // console.log("createProListingSuccess", response);
        dispatch(createProListingSuccess(response));
        const id = response.data.data.id.uuid;
        const { currentUser } = getState().user;
        const { email } = currentUser.attributes;
        const { firstName, lastName, displayName } = currentUser.attributes.profile;
        dispatch(updateProfile(
          {
            publicData: {
              userlistingId: id
            }
          }
        ))
        const {
          title = '',
          description = '',
          price,
          geolocation,
          availabilityPlan = '',
          state = '',
          publicData = {},
        } = actionPayload || {};
 
        
        createAlgoliaData({
          objectID: id,
          _geoloc:
            geolocation && geolocation.lat ? { lat: geolocation.lat, lng: geolocation.lng } : {},
          title,
          description,
          price,
          availabilityPlan,
          state,
          userType: currentUser?.attributes?.profile?.publicData?.userType,
          ...publicData,
          authorData: { firstName, lastName, email, displayName, userId: currentUser?.id?.uuid },
        });
      })
      .catch(e => dispatch(createProListingError(storableError(e))));
  };
};

export const updateProListing = actionPayload => {
  return (dispatch, getState, sdk) => {
    dispatch(updateProListingRequest());

    return sdk.ownListings
      .update(actionPayload, { expand: true })
      .then(response => {
        // console.log("updateProListing", response);
        dispatch(updateProListingSuccess(response));

        const id = response.data.data.id.uuid;
        const { currentUser } = getState().user;
        const { email } = currentUser.attributes;

        const { firstName, lastName, displayName } = currentUser.attributes.profile;

        const {
          title = '',
          description = '',
          price,
          geolocation,
          availabilityPlan = '',
          state = '',
          publicData = {},
        } = actionPayload || {};

        updateAlgoliaData({
          objectID: id,
          _geoloc:
            geolocation && geolocation.lat ? { lat: geolocation.lat, lng: geolocation.lng } : {},
          title,
          description,
          price,
          availabilityPlan,
          state,
          userType: currentUser?.attributes?.profile?.publicData?.userType,
          ...publicData,
          authorData: { firstName, lastName, email, displayName, userId: currentUser?.id?.uuid },
        });
      })
      .catch(e => dispatch(updateProListingError(storableError(e))));
  };
};

export const queryProListing = () => {
  return (dispatch, getState, sdk) => {
    dispatch(queryProListingRequest());

    return sdk.ownListings
      .query()
      .then(response => {
        dispatch(queryProListingSuccess(response));
      })
      .catch(e => dispatch(queryProListingError(storableError(e))));
  };
};

export const loadData = (params, search, config) => (dispatch, getState, sdk) => {
  return Promise.all([dispatch(queryProListing())]);
};