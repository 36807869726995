// These helpers are calling this template's own server-side routes
// so, they are not directly calling Marketplace API or Integration API.
// You can find these api endpoints from 'server/api/...' directory

import { get } from 'lodash';
import appSettings from '../config/settings';
import { types as sdkTypes, transit } from './sdkLoader';
import Decimal from 'decimal.js';

export const apiBaseUrl = marketplaceRootURL => {
  const port = process.env.REACT_APP_DEV_API_SERVER_PORT;
  const useDevApiServer = process.env.NODE_ENV === 'development' && !!port;

  // In development, the dev API server is running in a different port
  if (useDevApiServer) {
    return `http://localhost:${port}`;
  }

  // Otherwise, use the given marketplaceRootURL parameter or the same domain and port as the frontend
  return marketplaceRootURL ? marketplaceRootURL.replace(/\/$/, '') : `${window.location.origin}`;
};

// Application type handlers for JS SDK.
//
// NOTE: keep in sync with `typeHandlers` in `server/api-util/sdk.js`
export const typeHandlers = [
  // Use Decimal type instead of SDK's BigDecimal.
  {
    type: sdkTypes.BigDecimal,
    customType: Decimal,
    writer: v => new sdkTypes.BigDecimal(v.toString()),
    reader: v => new Decimal(v.value),
  },
];

const serialize = data => {
  return transit.write(data, { typeHandlers, verbose: appSettings.sdk.transitVerbose });
};

const deserialize = str => {
  return transit.read(str, { typeHandlers });
};

const methods = {
  POST: 'POST',
  GET: 'GET',
  PUT: 'PUT',
  PATCH: 'PATCH',
  DELETE: 'DELETE',
};

// If server/api returns data from SDK, you should set Content-Type to 'application/transit+json'
const request = (path, options = {}) => {
  const url = `${apiBaseUrl()}${path}`;
  const { credentials, headers, body, ...rest } = options;

  // If headers are not set, we assume that the body should be serialized as transit format.
  const shouldSerializeBody =
    (!headers || headers['Content-Type'] === 'application/transit+json') && body;
  const bodyMaybe = shouldSerializeBody ? { body: serialize(body) } : {};

  const fetchOptions = {
    credentials: credentials || 'include',
    // Since server/api mostly talks to Marketplace API using SDK,
    // we default to 'application/transit+json' as content type (as SDK uses transit).
    headers: headers || { 'Content-Type': 'application/transit+json' },
    ...bodyMaybe,
    ...rest,
  };

  return window.fetch(url, fetchOptions).then(res => {
    const contentTypeHeader = res.headers.get('Content-Type');
    const contentType = contentTypeHeader ? contentTypeHeader.split(';')[0] : null;

    if (res.status >= 400) {
      return res.json().then(data => {
        let e = new Error();
        e = Object.assign(e, data);

        throw e;
      });
    }
    if (contentType === 'application/transit+json') {
      return res.text().then(deserialize);
    } else if (contentType === 'application/json') {
      return res.json();
    }
    return res.text();
  });
};

// Keep the previous parameter order for the post method.
// For now, only POST has own specific function, but you can create more or use request directly.
const post = (path, body, options = {}) => {
  const requestOptions = {
    ...options,
    method: methods.POST,
    body,
  };

  return request(path, requestOptions);
};

// Fetch transaction line items from the local API endpoint.
//
// See `server/api/transaction-line-items.js` to see what data should
// be sent in the body.
export const transactionLineItems = body => {
  return post('/api/transaction-line-items', body);
};

// Initiate a privileged transaction.
//
// With privileged transitions, the transactions need to be created
// from the backend. This endpoint enables sending the order data to
// the local backend, and passing that to the Marketplace API.
//
// See `server/api/initiate-privileged.js` to see what data should be
// sent in the body.
export const initiatePrivileged = body => {
  return post('/api/initiate-privileged', body);
};

// Transition a transaction with a privileged transition.
//
// This is similar to the `initiatePrivileged` above. It will use the
// backend for the transition. The backend endpoint will add the
// payment line items to the transition params.
//
// See `server/api/transition-privileged.js` to see what data should
// be sent in the body.
export const transitionPrivileged = body => {
  return post('/api/transition-privileged', body);
};

// Create user with identity provider (e.g. Facebook or Google)
//
// If loginWithIdp api call fails and user can't authenticate to Marketplace API with idp
// we will show option to create a new user with idp.
// For that user needs to confirm data fetched from the idp.
// After the confirmation, this endpoint is called to create a new user with confirmed data.
//
// See `server/api/auth/createUserWithIdp.js` to see what data should
// be sent in the body.
export const createUserWithIdp = body => {
  return post('/api/auth/create-user-with-idp', body);
};
export const deleteFromCloudinary = body => {
  return post('/api/deleteFromCloudinary', body);
};
export const deleteImageFromCloudinary = body => {
  return post('/api/deleteImageFromCloudinary', body);
};
export const uploadToCloudinary = body => {
  return post('/api/uploadToCloudinary', body);
};

// Algolia search data
export const createAlgoliaData = body => {
  return post('/api/createAlgoliaData', body);
};
export const updateAlgoliaData = body => {
  return post('/api/updateAlgoliaData', body);
};
export const deleteAlgoliaData = body => {
  return post('/api/deleteAlgoliaData', body);
};
export const deleteOneAlgoliaData = body => {
  return post('/api/deleteOneAlgoliaData', body);
};
export const searchAlgoliaData = body => {
  return post('/api/searchAlgoliaData', body);
};
export const deleteAwsFileApi = body => {
  return post('/api/deleteAwsFile', body);
};
export const updateBidListing = body => {
  return post('/api/updateBidListing', body);
};
export const updateCloudUrls = body => {
  return post('/api/update-cloud-urls', body);
};
export const updateListing = body => {
  return post('/api/updateListing', body);
};
export const updateTransactionMetadata = body => {
  return post('/api/updateTransactionMetadata', body);
};

// SENDGRID
export const proCompletedProfile = body => {
  return post('/api/proCompletedProfile', body);
};
export const singleMailApiCollection = body => {
  return post('/api/send_mail', body);
};


export const updatestrapiMetadata = body => {
  return post('/api/updatestrapiMetadata', body);
}

export const listExistCheck = body => {
  return post('/api/list-exist-check', body);
};
export const upFrontCreatePayout = body => {
  return post('/api/upFrontCreatePayout', body);
};
export const singleMilestonePayout = body => {
  return post('/api/singleMilestonePayout', body);
};

export const updateMilestoneRevison = body => {
  return post('/api/updateMilestoneRevison', body);
};
export const updateManuallyPayment = body => {
  return post('/api/updateManuallyPayment', body);
};
export const updateUserForDeclineBid = body => {
  return post('/api/updateUserForDeclineBid', body);
};
export const updateListingState = body => {
  return post('/api/updateListingData', body);
};
export const closedListing = body => {
  return post('/api/closedListing', body);
};

//collection
export const insertCollection = (body) => {
  return post('/api/insert-collection', body);
};
export const updateCollection = (body) => {
  return post('/api/update-collection', body);
};
export const getCollection = (body) => {
  return post('/api/get-collection', body);
};
export const generatePresignedUrl = (body) => {
  return post('/api/presignedUrl', body);
};

export const pausedFinalPayout = (body) => {
  return post('/api/dispute-raised', body);
};

