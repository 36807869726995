import React, { useState } from 'react';
import css from './VettedProfessionalsCards.module.css'
import Button from '../Button/Button';
import IconCollection from '../IconCollection/IconCollection';
import NoImageIcon from '../ResponsiveImage/NoImageIcon';
import NamedLink from '../NamedLink/NamedLink';

import classNames from 'classnames';
const VettedProfessionalsCards = ({ proListings, isAuthenticated,
    currentUser }) => {
    const [showFullDescription, setShowFullDescription] = useState(false);
    const userId = currentUser && currentUser?.id?.uuid
    const description = "Lorem ipsum dolor sit amet, consecur adipiscing elit, sed do eiusmod tempor incididunt ut labor , sed do eiusmod tempor incididunt ut labor... "
    return (
        <div className={css.professionalCardWrapper}>
            <div className={css.professionalCards}>

                {proListings && proListings?.map((job) => (

                    <div className={classNames(css.professionalCard, isAuthenticated ? null : css.blurCard)}>

                        <div className={css.cardContents}>
                            <div className={css.mainContent}>
                                <div className={css.cardImage}>
                                    {job?.attributes?.publicData?.portfolioImages?.length > 0 ? (
                                        <div className={css.cardImage}>
                                                  {job?.attributes?.publicData?.portfolioImages[0]?.secure_url.includes(".pdf") ?
                                                <img src="https://res.cloudinary.com/dic6op0in/image/upload/v1723813064/catgoryImages/image-20240814-182236_ukhqpg.png" alt="no image icon" />
                                                :
                                                <img src={job?.attributes?.publicData?.portfolioImages[0]?.secure_url} alt="listing-img" />}
                                        </div>
                                    ) : (
                                        <div className={css.cardImage}>
                                             <img src="https://res.cloudinary.com/dic6op0in/image/upload/v1723813064/catgoryImages/image-20240814-182236_ukhqpg.png" alt="no image icon" />
                                        </div>
                                    )}
                                </div>
                                <div>
                                    <h3 className={css.nameText}>{job?.attributes?.title}</h3>
                                    <h6 className={css.professionText}>{job?.attributes?.publicData?.spokenLanguages && job?.attributes?.publicData?.spokenLanguages[0]?.label} | {job?.attributes?.publicData?.professionalLocation?.address?.slice(0, 15)} </h6>
                                    <div className={css.starIcons}>
                                        <IconCollection name="STAR_ICON" />
                                        <IconCollection name="STAR_ICON" />
                                        <IconCollection name="STAR_ICON" />
                                        <IconCollection name="STAR_ICON" />
                                        <IconCollection name="STAR_ICON" />
                                    </div>
                                </div>
                            </div>
                            <div className={css.bottomBox}>
                                {currentUser && job?.attributes?.publicData?.userId ? (
                                    <NamedLink
                                        name="ProfessionalProfilePage"
                                        params={{ id: job?.attributes?.publicData?.userId || "" }}
                                        className={css.link}
                                        isSearchPage={true}
                                    >
                                        <p onClick={() => setShowFullDescription(!showFullDescription)} className={css.description}>
                                            {showFullDescription ? (
                                                <span>{job?.attributes?.description}</span>
                                            ) : (
                                                <span>{job?.attributes?.description?.slice(0, 95)}</span>
                                            )}
                                            {(job?.attributes?.description?.length > 95) && <span className={css.readStatus} onClick={() => setShowFullDescription(!showFullDescription)}>
                                                {showFullDescription ? '...READ LESS' : '...READ MORE'}
                                            </span>}
                                        </p>
                                    </NamedLink>
                                ) : (
                                    <p onClick={() => setShowFullDescription(!showFullDescription)} className={css.description}>
                                        {showFullDescription ? (
                                            <span>{job?.attributes?.description}</span>
                                        ) : (
                                            <span>{job?.attributes?.description?.slice(0, 95)}</span>
                                        )}
                                        {(job?.attributes?.description?.length > 95) && <span className={css.readStatus} onClick={() => setShowFullDescription(!showFullDescription)}>
                                            {showFullDescription ? '...READ LESS' : '...READ MORE'}
                                        </span>}
                                    </p>
                                )}

                                <div>
                                    <div className={css.skillContents}>
                                        <div className={css.skillContent}>
                                            {job?.attributes?.publicData?.disciplines?.slice(0, 2).map(tool => {
                                                return (
                                                    <span key={tool.value} className={css.skillContentInner}>{tool.label}</span>
                                                );
                                            })}
                                        </div>
                                        <div className={css.skillContent}>
                                            {job?.attributes?.publicData?.proSoftwareTools?.slice(0, 2).map(tool => {
                                                return (
                                                    <span key={tool.value} className={css.skillContentInner}>{tool.label}</span>
                                                );
                                            })}
                                        </div>
                                    </div>

                                    {currentUser && job?.attributes?.publicData?.userId ? (
                                        <NamedLink
                                            name="ProfessionalProfilePage"
                                            params={{ id: job?.attributes?.publicData?.userId || "" }}
                                            className={css.link}
                                            isSearchPage={true}
                                        >
                                            <Button className={css.viewButton}>VIEW PORTFOLIO</Button>
                                        </NamedLink>
                                    ) : (
                                        <Button className={css.viewButton}>VIEW PORTFOLIO</Button>
                                    )}

                                </div>
                            </div>
                        </div>

                    </div>
                ))}

            </div>
            <NamedLink
                name={"SearchPage"}
                className={css.link}
                isSearchPage={true}
            >
                <Button className={css.joinButton}>Join Now & View More</Button></NamedLink>
        </div>
    );
};

export default VettedProfessionalsCards;