

export const transitions = {
    REQUEST_PAYMENT: 'transition/request-payment',
    MILESTONE_REQUEST_PAYMENT: 'transition/Milestone-request-payment',
    MILESTONE_EXPIRE_PAYMENT: "transition/Milestone-expire-payment",
    EXPIRE_PAYMENT: 'transition/expire-payment',
    CONFIRM_PAYMENT: 'transition/confirm-payment',
    MILESTONE_CONFIRM_PAYMENT: "transition/Milestone-confirm-payment",
    AUTO_PAYMENT: 'transition/auto-payout',
    PRO_DECLINED: 'transition/pro-declined',
    PRO_ACCEPTED: 'transition/pro-accepted',
    SENT_MILESTONE: "transition/sent-milestone",
    SENT_MILESTONE_INSATLLMENT: "transition/sent-milestone-installment",
    SENT_MILESTONE_FINAL_INSATLLMENT: "transition/sent-milestone-final-installment",
    MILESTONE_FINAL_AUTO_INSATLLMENT: "transition/milestone-final-auto-complete",
    SENT_MILESTONE_TWO_INSATLLMENT: "transition/sent-milestone-two-installment",
    MILESTONE_AUTO_INSATLLMENT: "transition/milestone-auto-complete",
    ACCEPTED_MILESTONE: 'transition/accepted-milestone',
    NEXT_MILESTONE: "transition/next-milestone",
    DECLINE_MILESTONE: 'transition/declined-milestone',
    REPEAT_MILESTONE: 'transition/repeat-milestone',
    COMPLETE_MILESTONE: 'transition/milestone-complete',
    COMPLETE: "transition/complete",
    REVIEW_1_BY_PROVIDER: 'transition/review-1-by-provider',
    REVIEW_2_BY_PROVIDER: 'transition/review-2-by-provider',
    REVIEW_1_BY_CUSTOMER: 'transition/review-1-by-customer',
    REVIEW_2_BY_CUSTOMER: 'transition/review-2-by-customer',
    INSATLLMENT_COMPLETE:"transition/installment-complete",
};

/**
 * States
 *
 * These constants are only for making it clear how transitions work together.
 * You should not use these constants outside of this file.
 *
 * Note: these states are not in sync with states used transaction process definitions
 *       in Marketplace API. Only last transitions are passed along transaction object.
 */
export const states = {
    INITIAL: 'initial',
    PENDINGPAYMENT: 'pending-payment',
    MILESTONEPENDINGPAYMENT: 'Milestone-pending-payment',
    PAYMENTEXPIRED: 'payment-expired',
    INTIALPAYMENT: 'intialPayment',
    MILESTONEPAYMENT: "Milestone-payment",
    DECLINED: 'decline',
    ACCEPTED: "accepted",
    MILESTONEFIRSTDELIVERED: 'milestone-first-delivered',
    MILESTONETWODELIVERED: 'milestone-two-delivered',
    AUTOCOMPLETE: "auto-completed",
    SENDFINALMILESTONE: 'sent-final-milestone',
    MILESTONEDELIVERED: 'milestone-delivered',
    PAID: 'payout',
    MILESTONEACCEPTED: 'milestone-accepted',
    MILESTONEDECLINED: "milestone-declined",
    MILESTONECOMPLETED: "milestone-completed",
    COMPLETE: "complete",
    REVIEWED: 'reviewed',
    REVIEWED_BY_CUSTOMER: 'reviewed-by-customer',
    REVIEWED_BY_PROVIDER: 'reviewed-by-provider',
};

/**
 * Description of transaction process graph
 *
 * You should keep this in sync with transaction process defined in Marketplace API
 *
 * Note: we don't use yet any state machine library,
 *       but this description format is following Xstate (FSM library)
 *       https://xstate.js.org/docs/
 */
export const graph = {
    // id is defined only to support Xstate format.
    // However if you have multiple transaction processes defined,
    // it is best to keep them in sync with transaction process aliases.
    id: 'default-accept-bid/release-1',

    // This 'initial' state is a starting point for new transaction
    initial: states.INITIAL,

    // States
    states: {
        [states.INITIAL]: {
            on: {
                [transitions.REQUEST_PAYMENT]: states.PENDINGPAYMENT,
                [transitions.MILESTONE_REQUEST_PAYMENT]: states.MILESTONEPENDINGPAYMENT,
            },
        },
        [states.MILESTONEPENDINGPAYMENT]: {
            on: {

                [transitions.MILESTONE_CONFIRM_PAYMENT]: states.MILESTONEPAYMENT,


            },
        },
        [states.PENDINGPAYMENT]: {
            on: {

                [transitions.CONFIRM_PAYMENT]: states.INTIALPAYMENT,


            },
        },
        [states.PAYMENTEXPIRED]: { type: 'final' },
        [states.DECLINED]: { type: 'final' },
        [states.INTIALPAYMENT]: {
            on: {
                [transitions.EXPIRE_PAYMENT]: states.PAYMENTEXPIRED,
                [transitions.PRO_DECLINED]: states.DECLINED,
                [transitions.PRO_ACCEPTED]: states.ACCEPTED,
            },
        },
        [states.ACCEPTED]: {
            on: {
                [transitions.SENT_MILESTONE]: states.MILESTONEDELIVERED,
                [transitions.AUTO_PAYMENT]: states.PAID,

            },
        },
        [states.MILESTONEDELIVERED]: {
            on: {
                [transitions.COMPLETE_MILESTONE]: states.MILESTONECOMPLETED,
            },
        },
        [states.PAID]: {
            on: {
                [transitions.SENT_MILESTONE_INSATLLMENT]: states.MILESTONEFIRSTDELIVERED,
            },
        },
        [states.MILESTONEFIRSTDELIVERED]: {
            on: {
                [transitions.SENT_MILESTONE_TWO_INSATLLMENT]: states.MILESTONETWODELIVERED,

            },
        },
        [states.MILESTONETWODELIVERED]: {
            on: {
                [transitions.MILESTONE_AUTO_INSATLLMENT]: states.AUTOCOMPLETE,
                [transitions.SENT_MILESTONE_FINAL_INSATLLMENT]: states.SENDFINALMILESTONE,

            },
        },

        [states.SENDFINALMILESTONE]: {
            on: {
                [transitions.MILESTONE_FINAL_AUTO_INSATLLMENT]: states.AUTOCOMPLETE,

            },
        },
        [states.AUTOCOMPLETE]: {
            on: {
                [transitions.INSATLLMENT_COMPLETE]: states.COMPLETE,
                
            },
        },

        [states.MILESTONEACCEPTED]: {
            on: {
                [transitions.NEXT_MILESTONE]: states.MILESTONEDELIVERED,
            },
        },
        [states.MILESTONEDECLINED]: {
            on: {
                [transitions.REPEAT_MILESTONE]: states.MILESTONEDELIVERED,
            },
        },
        [states.MILESTONECOMPLETED]: {
            on: {
                [transitions.COMPLETE]: states.COMPLETE,
            },
        },
        [states.COMPLETE]: {
            on: {
                [transitions.REVIEW_1_BY_CUSTOMER]: states.REVIEWED_BY_CUSTOMER,
                [transitions.REVIEW_1_BY_PROVIDER]: states.REVIEWED_BY_PROVIDER,
            },
        },

        [states.REVIEWED_BY_CUSTOMER]: {
            on: {
                [transitions.REVIEW_2_BY_PROVIDER]: states.REVIEWED,
            },
        },
        [states.REVIEWED_BY_PROVIDER]: {
            on: {
                [transitions.REVIEW_2_BY_CUSTOMER]: states.REVIEWED,
            },
        },
        [states.REVIEWED]: { type: 'final' },


    },
};

// Check if a transition is the kind that should be rendered
// when showing transition history (e.g. ActivityFeed)
// The first transition and most of the expiration transitions made by system are not relevant
export const isRelevantPastTransition = transition => {
    return [
        transitions.REPEAT_MILESTONE,
        transitions.PRO_ACCEPTED,
        // transitions.REQUEST_PAYMENT,
        transitions.ACCEPTED_MILESTONE,
        transitions.EXPIRE_PAYMENT,
        // transitions.COMPLETE,
         transitions.COMPLETE_MILESTONE,
        transitions.CONFIRM_PAYMENT,
        transitions.PRO_DECLINED,
        transitions.DECLINE_MILESTONE,
        transitions.NEXT_MILESTONE,
        transitions.SENT_MILESTONE,
        transitions.SENT_MILESTONE_INSATLLMENT,
        transitions.SENT_MILESTONE_TWO_INSATLLMENT,
        transitions.MILESTONE_AUTO_INSATLLMENT,
        transitions.SENT_MILESTONE_FINAL_INSATLLMENT,
        transitions.MILESTONE_FINAL_AUTO_INSATLLMENT,
        transitions.REVIEW_1_BY_CUSTOMER,
        transitions.REVIEW_1_BY_PROVIDER,
        transitions.REVIEW_2_BY_CUSTOMER,
        transitions.REVIEW_2_BY_PROVIDER,
    ].includes(transition);
};

// Processes might be different on how reviews are handled.
// Default processes use two-sided diamond shape, where either party can make the review first

export const isCustomerReview = transition => {
    return [transitions.REVIEW_1_BY_CUSTOMER, transitions.REVIEW_2_BY_CUSTOMER].includes(transition);
  };
  
  // Processes might be different on how reviews are handled.
  // Default processes use two-sided diamond shape, where either party can make the review first
  export const isProviderReview = transition => {
    return [transitions.REVIEW_1_BY_PROVIDER, transitions.REVIEW_2_BY_PROVIDER].includes(transition);
  };
  
// Check if the given transition is privileged.
//
// Privileged transitions need to be handled from a secure context,
// i.e. the backend. This helper is used to check if the transition
// should go through the local API endpoints, or if using JS SDK is
// enough.
export const isPrivileged = transition => {
    return [transitions.REQUEST_PAYMENT].includes(
        transition
    );
};

// Check when transaction is completed (booking over)
export const isCompleted = transition => {
    const txCompletedTransitions = [
        transitions.COMPLETE,
        transitions.REVIEW_1_BY_CUSTOMER,
        transitions.REVIEW_1_BY_PROVIDER,
        transitions.REVIEW_2_BY_CUSTOMER,
        transitions.REVIEW_2_BY_PROVIDER,

    ];
    return txCompletedTransitions.includes(transition);
};

// Check when transaction is refunded (booking did not happen)
// In these transitions action/stripe-refund-payment is called
export const isRefunded = transition => {
    return false;
};

export const statesNeedingProviderAttention = [states.INTIALPAYMENT];
